import { Injectable, NgZone } from '@angular/core';
import { HttpService } from './http.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { NzIconService } from 'ng-zorro-antd/icon';
import { hezky_svg_data } from '../hezky_svg_data';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import { selectUser } from '../../modules/auth/state/auth.state';
import { Location, NzTreeNode, UserData } from '../types';
import { NzModalService } from 'ng-zorro-antd/modal';
import { API_ENDPOINTS } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { combineLatest, map, distinctUntilChanged, filter, Observable, of, tap } from 'rxjs';
import { selectLocation } from '../../modules/location-tree/state/location-tree.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleAuthorizationService } from './role-authorization.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  temp_pref: 'D' | 'F' = 'D';
  time_zone!: string;
  assignedLocations: any;
  nLevelPayload: { location_id: number[], timezone: string } = {
    location_id: [],
    timezone: 'Asia/Kolkata',
  }
  user_data!: UserData;
  user_name!: string;

  customer_origins = [
    'http://localhost:4200',
    'https://abbott.hezkytech.com',
    'https://usv.hezkytech.com',
    'https://haffkine.hezkytech.com',
    'https://samarpan.hezkytech.com'
  ];

  constructor(
    public httpSvc: HttpService,
    public router: Router,
    public drawerSvc: NzDrawerService,
    public message: NzMessageService,
    public notification: NzNotificationService,
    public modalSvc: NzModalService,
    public roleService: RoleAuthorizationService,
    public store: Store,
    private ngZone: NgZone,
    private iconService: NzIconService,
  ) {
    this.handleUserAndLocationChanges();
    this.initializeCustomIcons();
    this.roleService.setUserRole(this.user_data?.userRoles);
  }

  /**
   * Handles changes to user and location data.
   * Synchronizes user preferences and location data with the application state.
   */
  private handleUserAndLocationChanges(): void {
    combineLatest([this.store.select(selectUser), this.store.select(selectLocation)])
      .pipe(
        filter(([user]) => !!user),
        distinctUntilChanged(),
        tap(([user, location]) => {
          this.temp_pref = user?.data?.temp_preference;
          this.time_zone = user?.data?.timezone;
          this.user_data = user?.data;
          this.user_name = `${this.user_data?.first_name ?? ''} ${this.user_data?.last_name ?? ''}`.trim();
          this.assignedLocations = user?.accessLocation?.[0]?.location_ids?.split(',').map(Number) ?? [];
          this.nLevelPayload['location_id'] = location ?? this.assignedLocations;
          this.nLevelPayload['timezone'] = this.time_zone;
        })
      )
      .subscribe();
  }
  /**
   * Initializes custom icons by adding them to the NzIconService.
   * This runs outside of Angular's zone to improve performance.
   */
  private initializeCustomIcons(): void {
    this.ngZone.runOutsideAngular(() => {
      hezky_svg_data.forEach((item: { name: string; value: string }) => {
        this.iconService.addIconLiteral(`hezky:${item.name}`, item.value);
      });
    });
  }
  /**
   * Recursively validates a form group by marking all of its controls as touched
   * and updating their validity.
   *
   * @param form The form group to validate.
   */
  private validateFormGroup(form: FormGroup): void {
    Object.values(form.controls).forEach((formControl: AbstractControl) => {
      formControl.markAsTouched();
      formControl.updateValueAndValidity({ onlySelf: true });
    });
  }
  /**
   * Validates a form group and returns true if the form is valid, false otherwise.
   * If the form is invalid, it will mark all controls as touched and update their validity.
   * It will also display a message to the user indicating that all required fields must be filled.
   *
   * @param form The form group to validate.
   * @returns True if the form is valid, false otherwise.
   */
  public validate(form: FormGroup): boolean {
    if (form.invalid) {
      this.validateFormGroup(form);
      this.message.info('Please fill all required fields!');
      return false;
    }
    return true;
  }
  /**
   * Calculates the relative difference between the given date and the current date.
   * Returns a human-readable string indicating the time difference.
   *
   * @param date - The date string in 'yyyy-MM-dd HH:mm:ss' format.
   * @returns A string representing the relative time difference, or 'N/A' if the input is invalid.
   */
  public getDateDifference(date: string | null): string {
    if (!date || typeof date !== 'string') return 'N/A';
    const propDate = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss');
    if (!propDate.isValid) return 'Invalid Date';
    const relativeTime = propDate.toRelative();
    return relativeTime ?? 'N/A';
  }
  public getDateDifferenceInTwoValue(startDate: string | null, endDate: string | null): string {
    if (!startDate || !endDate || typeof startDate !== 'string' || typeof endDate !== 'string') return 'N/A';
  
    const start = DateTime.fromFormat(startDate, 'yyyy-MM-dd HH:mm:ss');
    const end = DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss');
  
    if (!start.isValid || !end.isValid) return 'Invalid Date';
    // Get difference in all relevant units
    const diff = end.diff(start, ['years', 'months', 'days', 'hours', 'minutes']).toObject();
    // Convert decimal minutes to an integer
    const minutes = Math.round(diff.minutes ?? 0); // Proper rounding
    // Build readable output
    const parts: string[] = [];
    if (diff.years) parts.push(`${diff.years} year${diff.years > 1 ? 's' : ''}`);
    if (diff.months) parts.push(`${diff.months} month${diff.months > 1 ? 's' : ''}`);
    if (diff.days) parts.push(`${diff.days} day${diff.days > 1 ? 's' : ''}`);
    if (diff.hours) parts.push(`${diff.hours} hour${diff.hours > 1 ? 's' : ''}`);
    if (minutes) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  
    return `${parts.join(', ')}`;
  }
  
  /**
   * Gets the formatted value for a given sensor type and matrix key.
   * If no sensor is found with the given type or if the sensor's value is null,
   * an empty string is returned.
   * @param data - The array of sensor data.
   * @param sensor_type - The type of sensor to get the value for.
   * @param matrixKey - The key to get the value from.
   * @returns The formatted value, or an empty string if no value is found.
   */
  public getFormattedValue(data: any[], sensor_type: string, matrixKey: string): string {
    const sensor = data.find((s) => s.sensor_type === sensor_type);
    if (!sensor || sensor[matrixKey] === null) return '';
    return this.getFormattedTemp(sensor[matrixKey], matrixKey);
  }
  /**
   * Formats a given temperature value according to the current temperature preference.
   * @param value - The temperature value to format.
   * @param matrixKey - The matrix key to format the value for. Supported keys are 'temperature', 'pressure', and 'humidity'.
   * @returns A formatted string representing the value.
   * @example
   * const value = 25;
   * const matrixKey = 'temperature';
   * const formattedValue = commonSvc.getFormattedTemp(value, matrixKey);
   * // formattedValue is '25°C'
   */
  public getFormattedTemp(value: number, matrixKey: string): string {
    if (matrixKey === 'temperature') return `${this.temp_pref === 'D' ? value : (value * 1.8 + 32).toFixed(1)}${this.getMatrixSymbol(matrixKey)}`;
    if (matrixKey === 'pressure') return `${value}${this.getMatrixSymbol(matrixKey)}`;
    if (matrixKey === 'humidity') return `${value}${this.getMatrixSymbol(matrixKey)}`;
    return value.toString();
  }
  /**
   * Checks if any sensor in the given data has the given capability.
   * @param data - The data object to check.
   * @param matrix - The capability to check for.
   * @returns True if any sensor has the given capability, false otherwise.
   */
  public checkCapabilities(data: any, matrix: string): boolean {
    return data?.sensors?.some((sensor: any) =>
      sensor?.capabilities.includes(matrix)
    );
  }
  /**
   * Returns the appropriate symbol for a given matrix type.
   * Symbols are based on the current temperature preference for temperature,
   * and standard units for humidity and pressure.
   * 
   * @example
   * const matrix = 'temperature';
   * const symbol = commonSvc.getMatrixSymbol(matrix);
   * // symbol is '°C'
   * 
   * @param matrix - The matrix type ('temperature', 'humidity', 'pressure').
   * @returns The corresponding symbol for the matrix type.
   *          Returns an empty string if the matrix type is unrecognized.
   */
  public getMatrixSymbol(matrix: string): string {
    switch (matrix) {
      case 'temperature':
        return this.temp_pref === 'D' ? "°C" : "°F";
      case 'humidity':
        return '%';
      case 'pressure':
        return 'hPa';
      default:
        return '';
    }
  }
  /**
   * Returns the value to use for tracking an item in an NgFor loop.
   * If the item has an 'id' property, that value is returned. Otherwise, the loop index is returned.
   * 
   * @param index - The loop index.
   * @param item - The item being looped over.
   * @returns The value to use for tracking the item.
   */
  public trackByFn(index: number, item: any): any {
    return item.id ?? index;
  }
  /**
   * Groups the given data array by sensor type.
   * @param data - The data array to group.
   * @returns An object with the sensor type as the key and an array of sensors as the value.
   *          The sensor type is trimmed and converted to lowercase before being used as the key.
   * @example
   * const data = [
   *   { sensor_type: 'hot spot', temperature: 25 },
   *   { sensor_type: 'cold spot', temperature: 20 },
   * ];
   * const groupedData = commonSvc.groupBySensorType(data);
   * // groupedData is { 'hot spot': [{ sensor_type: 'hot spot', temperature: 25 }],
   * //                    'cold spot': [{ sensor_type: 'cold spot', temperature: 20 }] }
   */
  public groupBySensorType(data: any[]): Record<string, any[]> {
    return data.reduce((acc, sensor) => {
      const type = sensor.sensor_type?.trim().toLowerCase() || 'unknown';
      acc[type] = acc[type] || [];
      acc[type].push(sensor);
      return acc;
    }, {} as Record<string, any[]>);
  }
  /**
   * Groups the given data array by the given matrix type.
   * @param data - The data array to group.
   * @param matrix - The matrix type to group by.
   * @returns An object with the matrix type as the key and an array of sensors as the value.
   *          The matrix type is trimmed and converted to lowercase before being used as the key.
   * @example
   * const data = [
   *   { sensor_type: 'hot spot', temperature: 25 },
   *   { sensor_type: 'cold spot', temperature: 20 },
   * ];
   * const groupedData = commonSvc.groupByKey(data, 'sensor_type');
   * // groupedData is { 'hot spot': [{ sensor_type: 'hot spot', temperature: 25 }],
   * //                    'cold spot': [{ sensor_type: 'cold spot', temperature: 20 }] }
   */
  public groupByKey(data: any[], matrix: string): Record<string, any[]> {
    if (data) {
      return data?.reduce((acc, data) => {
        const type = data[matrix]?.trim() || 'unknown';
        acc[type] = acc[type] || [];
        acc[type].push(data);
        return acc;
      }, {} as Record<string, any[]>);
    }
    return {};
  }
  /**
   * Returns the color for a given sensor type and matrix type.
   * @param sensorType - The type of sensor.
   * @param matrixType - The type of matrix.
   * @returns A color string in the format #RRGGBB.
   * @example
   * const color = commonSvc.getColor('hot spot', 'temperature');
   * // color is #0165FF
   */
  public getColor(sensorType: string, matrixType: string): string {
    switch (matrixType) {
      case 'temperature':
        return sensorType === 'hot spot' ? '#0165FF' : '#0099FF';
      case 'pressure':
        return sensorType === 'hot spot' ? '#FF9F40' : '#FFC107';
      case 'humidity':
        return sensorType === 'hot spot' ? '#FF8D0F' : '#FFC107';
      default:
        return '#FF9F40';
    }
  }
  /**
   * Converts a data object into an array of NzTreeNode objects formatted for use with NzTreeSelect.
   * This function recursively processes each node in the data object to construct a tree structure.
   *
   * @param data - The input data object to be converted. Expected to be an object with keys as node titles and values as node data.
   * @returns An array of NzTreeNode objects representing the converted tree structure.
   */
  public convertToNzTreeSelectFormat(data: any): NzTreeNode[] {
    const result: NzTreeNode[] = [];
    if (!data || typeof data !== 'object') {
      return result;
    }
    const processNode = (nodeData: any, parentKey: string = ''): NzTreeNode[] => {
      const nodes: NzTreeNode[] = [];
      for (const key in nodeData) {
        if (Object.prototype.hasOwnProperty.call(nodeData, key)) {
          const currentNodeData = nodeData[key];
          const node: NzTreeNode = {
            title: key,
            key: `${parentKey}key-${key}`,
            children: [],
            isLeaf: false,
            extraData: {},
            level: parentKey ? parentKey.split('-').length - 1 : 0,
            type: 'group',
            expandable: true,
          };

          if (Array.isArray(currentNodeData)) {
            currentNodeData.forEach((location: Location) => {
              if (location && location.display_name && location.location_id) {
                node.children!.push({
                  title: location.display_name,
                  key: `${location.location_id}`,
                  isLeaf: true,
                  extraData: location,
                  level: node.level + 1,
                  type: 'location',
                  expandable: false
                });
              }
            });
          } else if (currentNodeData && typeof currentNodeData === 'object') {
            node.children!.push(...processNode(currentNodeData, node.key));
          }
          node.extraData = currentNodeData;
          if (node.children!.length === 0) {
            node.isLeaf = true;
          }
          nodes.push(node);
        }
      }
      return nodes;
    };
    result.push(...processNode(data));
    return result;
  }
  /**
   * Fetches the location tree for a given user based on their customer ID.
   *
   * @param payload - The input data object containing the customer ID.
   * @returns An Observable of the location tree.
   */
  public fetchAccessLocationTree(payload: { customerId: number }): Observable<any> {
    return this.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.LOCATION.LOCATION_BY_USER_ID, payload);
  }
  /**
   * Validates the password signature by sending a request to the server.
   *
   * @param data - The data object containing the necessary information for validation.
   * @returns An Observable of the server response.
   */
  public validatePasswordSignature(data: any): Observable<any> {
    return this.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.AUTH.VERIFY_PASSWORD, data);
  }
  /**
   * Validates the given password against the user's password.
   *
   * @param uniquePassword - The password to validate.
   * @returns An Observable of a boolean indicating whether the password is valid.
   */
  public validatePassword(uniquePassword: string): Observable<boolean> {
    if (uniquePassword.trim() === '') {
      this.message.error('Please Enter Valid Password', { nzDuration: 3000 });
      return of(false);
    } else {
      const requestData = { user_id: this.user_data.user_id, password: uniquePassword };
      return this.validatePasswordSignature(requestData).pipe(untilDestroyed(this),
        map((response: any) => {
          if (response?.status === 200 && response?.isVerified) {
            this.message.success(response?.vMessage || 'Password validated successfully!', { nzDuration: 3000 });
            return true;
          } else {
            this.message.error(response?.vMessage || 'Invalid password, please try again.', { nzDuration: 3000 });
            return false;
          }
        })
      );
    }
  }
}