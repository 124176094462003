import { Injectable } from '@angular/core';
import { IncidentModalComponent } from '../../modules/incidents/incident-modal/incident-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { API_ENDPOINTS } from '../../core/services/api.service';
import { CommonService } from './common.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ReportNameModalComponent } from '../components/modals/report-name-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  constructor(
    private modal: NzModalService ,
    public commonService : CommonService
  ) { }
  getAllNotifications(payload: any): Observable<any[]> {
    return this.commonService.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.NOTIFICATION.GET_ALL, payload);
  }
  markNotificationAsRead(payload: any): Observable<any[]> {
    return this.commonService.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.NOTIFICATION.MARK_READ, payload);
  }
  openIncidentPopup(incident_id: any): void {
    this.modal.create<IncidentModalComponent>({
      nzContent: IncidentModalComponent,
      nzWidth: '900px',
      nzFooter: null,
      nzMaskClosable: true,
      nzClosable: true,
      nzData: { incidentId: incident_id },
      nzClassName: 'incident-modal',
      nzBodyStyle: { padding: '0' },
      nzStyle: { top: '20' },
      nzCloseOnNavigation: true
    });
  }
  saveReportModal() : Observable<any> {
    const modalRef = this.modal.create({
      nzTitle: 'Save Report',
      nzContent: ReportNameModalComponent,
      nzWidth: '400px',
      nzMaskClosable: false,
      nzClosable: true,
      nzCentered: true,
      nzOkText: 'Save',
      nzCancelText: 'Cancel',
      nzCloseOnNavigation: true
    });
    return modalRef.afterClose;
  }
}
