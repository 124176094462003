import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReportService } from '../../modules/reports/services/report.service';
import { CommonService } from '../../shared/services/common.service';
import { AuthenticationService } from './authentication.service';
import { finalize, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { API_ENDPOINTS } from './api.service';

@Injectable({
  providedIn: 'root'
})
@UntilDestroy({ checkProperties: true })
export class DeepLinkingService {
  public isLoading: boolean = false;
  constructor(private commonSvc: CommonService, private reportSvc: ReportService, private authenticationService: AuthenticationService) { }

  handleQueryParams(params: any) {
    if (this.authenticationService.isAuthenticated() === false) {
      this.getCustomerById({ user_id: params.c }).pipe(
        untilDestroyed(this),
        finalize(() => this.isLoading = false)
      ).subscribe({
        next: (res) => {
          if(res.status === 200 && res.data[0].company_name) {
            setTimeout(() => {
              this.redirectToLogin('localhost', params);
            }, 1000);
          }
        },
        error: (err) => console.error(err)
      })
    }
    if (this.authenticationService.isAuthenticated()) {
      this.makeApiAndRouterCalls(params);
    }
  }
  makeApiAndRouterCalls(params: any) {
    switch (params.t) {
      case 'schedule_report':
        this.routeToScheduleReport(params);
        break;
      default:
        break;
    }
  }
  routeToScheduleReport(params: any) {
    this.isLoading = true;
    this.reportSvc.fetchMyReports({ user_id: this.commonSvc.user_data.user_id, timezone: this.commonSvc.nLevelPayload['timezone'] })
      .pipe(
        untilDestroyed(this),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res) => {
          if (res?.status === 200 && res?.data.length > 0) {
            let schedule_report = res?.data.find((report: any) => report?.report_id === Number(params.d));
            if (schedule_report) {
              this.reportSvc.viewReportHandler(schedule_report);
            } else {
              this.commonSvc.message.error('Report not found! Please try again later.', { nzDuration: 3000 });
              this.commonSvc.router.navigate(['auth/login']);
            }
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.commonSvc.message.error('Error fetching reports! Please try again later.', { nzDuration: 3000 });
          this.commonSvc.router.navigate(['auth/login']);
        }
      })
  }
  redirectToLogin(company_name: string, params: any) {
    switch (company_name) {
      case 'localhost':
          window.location.href = `http://localhost:4200/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
      case 'Abbott':
        window.location.href = `https://abbott.hezkytech.com/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
      case 'Samarpan':
        window.location.href = `https://samarpan.hezkytech.com/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
      case 'SKD':
        window.location.href = `https://skd.hezkytech.com/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
      case 'Haffkine':
        window.location.href = `https://haffkine.hezkytech.com/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
      case 'USV':
        window.location.href = `https://usv.hezkytech.com/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
      default:
        window.location.href = `http://localhost:4300/#/auth/login?${new URLSearchParams(params).toString()}`;
        break;
    }
  }
  getCustomerById(payload:any) : Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.CUSTOMER.GET_CUSTOMER_BY_ID , payload)
  }
}
