import { Component, OnInit } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";

@Component({
    selector: 'app-report-name-modal',
    template: `<form nz-form nzLayout="vertical">
    <nz-form-item>
        <nz-form-label nzFor="report_name">Report Name</nz-form-label>
            <nz-form-control>
                <input nz-input name="report_name" type="text" id="report_name" maxlength="30" [(ngModel)]="reportName" />
            </nz-form-control>
        </nz-form-item>
    </form>
    <div *nzModalFooter>
    <button nz-button nzType="default" type="button" (click)="destroyModal()">Cancel</button>
    <button nz-button nzType="primary" type="submit" (click)="submitForm()" [disabled]="reportName.length === 0">Submit</button>
</div>
  `,
})
export class ReportNameModalComponent implements OnInit {
    reportName: string = '';
    constructor(
        private modalRef: NzModalRef,
    ) { }
    destroyModal() {
        this.modalRef.close();
    }
    submitForm() {
        if (this.reportName.length > 0) {
            this.modalRef.close(this.reportName);
        }
    }
    ngOnInit(): void {

    }
}
