import { createFeature, createSelector, createReducer, on } from '@ngrx/store';
import { loginSuccess, logout, updateName, updatePref, updateNotfCount } from './auth.actions';

export interface AuthState {
    user: any | null;
}
export const initialState: AuthState = {
    user: null,
};

export const authFeature = createFeature({
    name: 'auth',
    reducer: createReducer(
        initialState,
        on(loginSuccess, (state, { user }) => ({ ...state, user })),
        on(updatePref, (state, { preference }) => ({ ...state, user: { ...state.user, data: { ...state.user.data, ...preference } } })),
        on(updateName, (state, { name }) => ({ ...state, user: { ...state.user, data: { ...state.user.data, ...name } } })),
        on(updateNotfCount, (state, { notificationCount }) => ({ ...state, user: { ...state.user.data.notificationCount, notificationCount } })),
        on(logout, () => ({ ...initialState }))
    ),
});

export const { name, reducer, selectAuthState } = authFeature;
export const selectUser = createSelector(selectAuthState, (state) => state.user);
