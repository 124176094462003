import { createAction, props } from '@ngrx/store';

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ user: any }>()
);

export const updateName = createAction('[Auth] Update Name', props<{ name: { first_name: string, last_name: string } }>());
export const updatePref = createAction('[Auth] Update Temperature Preference', props<{preference: { temp_preference: 'D' | 'F' , timezone: string }}>());
export const updateNotfCount = createAction('[Auth] Update Notification Count', props<{ notificationCount: number }>());
export const logout = createAction('[Auth] Logout');
