import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loginSuccess, logout } from '../../modules/auth/state/auth.actions';
import { delay, take } from 'rxjs/operators';
import { clearLocations } from '../../modules/location-tree/state/location-tree.actions';
import { CommonService } from '../../shared/services/common.service';
import { API_ENDPOINTS } from './api.service';
import { environment } from '../../../environments/environment';
import { selectLoginOrigin } from '../../state/app.state';
import { clearAppState, saveLoginOrigin } from '../../state/app.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private store: Store, private commonSvc: CommonService) { }
  public isAuthenticated(): boolean {
    const localAuth = localStorage.getItem('auth');
    if(localAuth) {
      return true;
    }
    const auth = document.cookie.split('; ').find(row => row.startsWith('auth='));
    if (!auth) {
      return false;
    }
    const cookieValue = auth.split('=')[1];
    if (!cookieValue) {
      return false;
    }
    try {
      const user = JSON.parse(decodeURIComponent(cookieValue));
      const auth_origin = document.cookie.split('; ').find(row => row.startsWith('auth_origin='));
      const auth_origin_value = auth_origin?.split('=')[1];
      this.store.dispatch(saveLoginOrigin({ origin: auth_origin_value || window.location.origin }));
      user ? window.location.href = `${window.location.origin}/${window.location.hash}` : window.location.href = `${auth_origin_value}/#/auth/login`;
      if (user && user.data && user.data.userRoles) {
        this.store.dispatch(loginSuccess({ user }));
        this.commonSvc.roleService.setUserRole(user.data.userRoles);
        localStorage.setItem('INIT', 'true');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error parsing authentication cookie:', error);
      return false;
    }
  }
  /**
   * Handles user logout functionality
   * - Broadcasts logout message to other tabs
   * - Clears application state and storage
   * - Removes auth cookies
   * - Redirects to login page
   * - Logs the logout event
   */
  public onLogout(): void {
    let payload = {
      user_id: this.commonSvc?.user_data?.user_id,
      type: "Web Logout", 
      message: `Web Account logout By ${this.commonSvc?.user_name}`,
      email: this.commonSvc?.user_data?.email,
      timezone: this.commonSvc?.nLevelPayload['timezone']
    }
    // Send logout log to server
    this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.PROFILE.USER_LOG, payload)
      .pipe(take(1),delay(1000))
      .subscribe({
        next: (res: any) => {
          if (res?.status === 200) {
            this.commonSvc.message.success('You have successfully logged out.', { nzDuration: 2000 });
          }
        },
        error: (err: any) => {
          this.commonSvc.message.error('Failed to logout. Please try again later.', { nzDuration: 2000 });
        },
        complete: () => {
          // Setup broadcast channel to notify other tabs about logout
          const broadcastChannel = new BroadcastChannel('tab_management');
          broadcastChannel.postMessage({ type: 'logout' });
          broadcastChannel.close();
          // Clear application state
          this.store.dispatch(logout());
          this.store.dispatch(clearLocations());
          localStorage.clear();
          sessionStorage.clear();
          // Remove auth cookie based on environment
          window.location.origin.includes('localhost') ?
            document.cookie = 'auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost; secure; samesite=lax' :
            document.cookie = 'auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.hezkytech.com; secure; samesite=strict';
          // Remove auth_origin cookie based on environment
          window.location.origin.includes('localhost') ?
            document.cookie = 'auth_origin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost; secure; samesite=lax' :
            document.cookie = 'auth_origin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.hezkytech.com; secure; samesite=strict';
          this.store.select(selectLoginOrigin).pipe(take(1),delay(1000)).subscribe({
            next: (origin) => {
              if (origin) {
                window.location.href = `${origin}/#/auth/login`;
              }
            },
            error: (err) => {
              console.error('Error selecting login origin:', err);
            },
            complete: () => {
              this.store.dispatch(clearAppState());
            }
          });
        }
      })
  }
}
