import { Component, Input, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { DeepLinkingService } from '../../../core/services/deep-linking.service';

interface Particle {
  x: number;
  y: number;
  speed: number;
  angle: number;
  scale: number;
}

@Component({
  selector: 'app-deep-link-page',
  templateUrl: './deep-link-page.component.html',
  styleUrls: ['./deep-link-page.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.5s ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DeepLinkPageComponent implements OnInit {
  @Input() isLoading: boolean = true;
  @Input() loadingTitle: string = '🚀 Preparing Your Reports • Please Wait...';
  @Input() loadingText: string = '';

  particles: Particle[] = [];
  private animationFrameId: number = 0;

  constructor(private route: ActivatedRoute, protected deepLinkingSvc: DeepLinkingService) {
    this.initializeParticles();
    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length > 0) this.deepLinkingSvc.handleQueryParams(params);
    });
  }

  private initializeParticles() {
    this.particles = Array(50).fill(null).map(() => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      speed: 0.5 + Math.random() * 2,
      angle: Math.random() * Math.PI * 2,
      scale: 0.5 + Math.random() * 0.5
    }));
  }
  ngOnInit() {
    this.startAnimation();
  }
  private startAnimation() {
    const animate = () => {
      this.updateParticles();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    animate();
  }

  private updateParticles() {
    this.particles.forEach(particle => {
      particle.x += Math.cos(particle.angle) * particle.speed;
      particle.y += Math.sin(particle.angle) * particle.speed;
      if (particle.x > window.innerWidth) particle.x = 0;
      if (particle.x < 0) particle.x = window.innerWidth;
      if (particle.y > window.innerHeight) particle.y = 0;
      if (particle.y < 0) particle.y = window.innerHeight;
      if (Math.random() < 0.01) {
        particle.angle += (Math.random() - 0.5) * 0.5;
      }
    });
  }
  ngOnDestroy() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }
}