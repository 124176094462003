<div class="container-fluid desktop:h-[100dvh] desktop:overflow-hidden">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <!-- Left Section -->
        <div nz-col class="gutter-row justify-center m-auto h-full p-32" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24"
            [nzXl]="8">
            <div class="flex justify-center">
                <img nz-image src="assets/svg/hezky_logo_V2.svg" alt="Hezky tech" class="logo" draggable="false">
            </div>
            <div class="flex justify-center">
                <p class="login_sub_title">Monitoring done<span class="underline underline-offset-8"><br>right</span>.
                </p>
            </div>
        </div>
        <!-- Right Section -->
        <div nz-col class="gutter-row flex flex-col justify-center min-h-screen background-container" [nzXs]="24"
            [nzSm]="24" [nzMd]="24" [nzLg]="24" [nzXl]="16" id="loginCard">
            <div class="card-w p-12 m-auto space-y-6 bg-[#ffffff]">
                <h4 class="text-3xl font-semibold text-center text-primary mb-3">Contact Us</h4>
                <span class="text-2xl mt-3">
                    <p class="address_details">501 - G Square,</p>
                    <p class="address_details">Jawahar Road,</p>
                    <p class="address_details">Ghatkopar East,</p>
                    <p class="address_details">Mumbai, Maharashtra 400077</p>
                    <p class="address_details">+91 99606 23334</p>
                </span>
                <span>
                    <a href="mailto:support@hezkytech.com">{{supportEmail}}</a>
                </span>

            </div>
        </div>
        <div class="flex mb-3 justify-between px-5">
            <div>
                <a class="text-white" [routerLink]="['/about-us']">About Us</a>
                <span class="text-white mx-3">|</span>
                <a class="text-gray" [routerLink]="['/contact-us']">Contact Us</a>
            </div>
            <div>
                <a class="text-white" [routerLink]="['/terms&conditions']"> Terms & Conditions</a>
                <span class="text-white mx-3">|</span>
                <a class="text-white" [routerLink]="['/privacy-policy']"> Privacy policy</a>
            </div>
        </div>
    </div>
</div>