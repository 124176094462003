<div class="p-6 max-w-4xl">
  <!-- Header with ID and DateTime -->
  <div class="flex justify-between items-center mb-6">
    <div class="flex items-center gap-4">
      <h2 class="text-lg font-semibold text-gray-800">Incident ID {{incidentDetails$.incident_id}}</h2>
      <div class="flex items-center gap-2 text-[12px] text-gray-600 bg-gray-100 py-1 px-2 rounded-sm">
        <i nz-icon nzType="calendar" nzTheme="outline" class="mr-1"></i>
        <span>{{incidentDetails$.triggered_time | date:'MMM dd,yyyy'}}</span>
        <i nz-icon nzType="clock-circle" nzTheme="outline" class="mr-1 ml-2"></i>
        <span>{{incidentDetails$.triggered_time | date:'HH:mm:ss'}}</span>
      </div>
    </div>
    <!-- <button nz-button nzType="text" (click)="close()" style="background: white !important;">
      <span nz-icon nzType="close" nzTheme="outline"></span>
    </button> -->
  </div>

  <!-- Asset and Location Details -->
  <div class="grid grid-cols-1 md:grid-cols-5 gap-3 mb-6">
    <!-- Incident Details -->
    <div class="flex flex-col col-span-2">
      <div class="flex items-start space-x-4">
        <div>
          <span style="font-size: 4rem !important;" nz-icon [nzType]="'hezky:SnowFlake'"></span>
        </div>
        <div class="flex flex-col">
          <a class="text-primary font-medium flex flex-nowrap items-center" (click)="close()" [routerLink]="['/assets', incidentDetails$?.asset_id]">
            {{ incidentDetails$?.asset_name || 'N/A' }}
            <span class="ml-2 mb-1 w-3 h-3 rounded-full inline-block align-middle" [ngClass]="{
                    'bg-danger': incidentDetails$?.severity === 'High',
                    'bg-[#FF8D0F]': incidentDetails$?.severity === 'Medium',
                    'bg-yellow-500': incidentDetails$?.severity === 'Low'
                  }">
            </span>
          </a>
          <div class="text-sm text-gray-600">{{ incidentDetails$?.display_name }}</div>
          <div class="text-sm text-gray-400">{{ incidentDetails$?.environment }}</div>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div class="col-span-3">
      <div class="flex gap-4">
        <!-- Assigned User -->
        <div class="flex-1">
          <label class="text-xs text-primary font-medium mb-1 block">Assigned User</label>
          <nz-select class="w-full" [(ngModel)]="incidentDetails$.assigned_to" nzShowSearch nzServerSearch
            nzPlaceHolder="Not Assigned" (nzFocus)="getUserList()"
            [disabled]="commonService.roleService.hasAccess([3,4])"
            (ngModelChange)="updateAssignTo(incidentDetails$.assigned_to)">
            <nz-option *ngFor="let user of users$" [nzValue]="user.user_id" [nzLabel]="user.username">
            </nz-option>
          </nz-select>
        </div>

        <!-- Status -->
        <!-- <div class="flex-1">
          <label class="text-xs text-primary font-medium mb-1 block">Status</label>
          <nz-select class="w-full" [(ngModel)]="incidentDetails$.status"
            [disabled]="commonService.roleService.hasAccess([3,4])" (ngModelChange)="updateStatus($event)"
            nzPlaceHolder="Status">
            <nz-option *ngFor="let item of status" [nzValue]="item.value" [nzLabel]="item.key">
            </nz-option>
          </nz-select>
        </div> -->

        <!-- Acknowledge Button -->
        <div class="flex-1">
          <button nz-button nzType="default" nzShape="round" class="w-full mt-5"
            [ngClass]="{'bg-success text-white': incidentDetails$.acknowledged === true}"
            [disabled]="incidentDetails$.acknowledged === true || commonService.roleService.hasAccess([3,4])"
            nz-popconfirm nzPopconfirmTitle="Are you sure you want to acknowledge this incident?" nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="acknowledgeIncident()">
            <i nz-icon nzType="check" nzTheme="outline"></i>
            {{incidentDetails$.acknowledged === null ? 'Acknowledge' : 'Acknowledged' }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <nz-divider class="my-4"></nz-divider>
  <!-- Temperature Alert Section -->
  <div class="grid grid-cols-1 sm:grid-cols-3 mb-2 p-6">
    <div class="col-span-1 sm:col-span-1 row-span-4 flex flex-nowrap">
      <ng-container *ngIf="incidentDetails$?.incident_type && incidentDetails$?.severity">
        <i style="font-size: 40px !important" [nzTooltipColor]="'#fff'" [nz-tooltip]="incidentDetails$.incident_type" nz-icon
          [nzType]="'hezky:'+incidentDetails$?.incident_type+'_'+incidentDetails$?.severity" class="mr-1">
        </i>
      </ng-container>
      <h3 class="text-5xl font-medium" [ngClass]="{
        'text-danger': incidentDetails$?.severity === 'High',
        'text-[#FF8D0F]': incidentDetails$?.severity === 'Medium',
        'text-yellow-500': incidentDetails$?.severity === 'Low'
      }" *ngIf="incidentDetails$?.incident_type as type">
        {{
          type === 'Temperature' ? formatTemperature(incidentDetails$.current_alarm, temp_pref) :
          type === 'Humidity' ? incidentDetails$.current_alarm + '%' :
          type === 'Pressure' ? incidentDetails$.current_alarm + 'Pa' : ''
        }}
      </h3>
    </div>
    <div class="col-span-1 sm:col-span-2 row-span-2">
      <p class="font-medium mt-1" [ngClass]="{
        'text-danger': incidentDetails$?.severity === 'High',
        'text-[#FF8D0F]': incidentDetails$?.severity === 'Medium',
        'text-yellow-500': incidentDetails$?.severity === 'Low'
      }">{{incidentDetails$.incident_name}}</p>
      <p class="text-gray-600 text-sm mt-0">
        {{incidentDetails$?.threshold_condition}} {{incidentDetails$?.threshold_value}}
        {{incidentDetails$?.incident_type === 'Temperature' ? '°C' :
        incidentDetails$?.incident_type === 'Humidity' ? '%' : 'Pa'}} {{(incidentDetails$?.triggered_time | relativeTime:'modal') }}
      </p>
    </div>
  </div>
  <nz-divider class="my-4"></nz-divider>

  <div>
    <h4 class="text-gray-700 font-medium mb-4">Incident Logs</h4>
    <!-- Timeline Items -->
    <div class="timeline-container">
      <div class="overlay" *ngIf="apiCallLoaders.isIncidentLogsLoading">
        <nz-spin class="mt-[25%]" nzTip="Loading...."></nz-spin>
      </div>
      <div class="timeline">
        <div class="timeline-item" *ngFor="let event of events; trackBy: commonService.trackByFn"
          [ngClass]="getEventClass(event)">
          <div class="timeline-content" [ngClass]="getContentClass(event)">
            <p>
              <i nz-icon nzType="calendar" nzTheme="outline" class="mr-1"></i>
              <span class="mr-2">{{ event.time | date : "MMM d,y" }}</span>
              <i nz-icon nzType="clock-circle" nzTheme="outline" class="mr-1"></i>
              <span>{{ event.time | date : "HH:mm:ss" }}</span>
            </p>
            <p>{{ event.description}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <nz-divider *ngIf="!commonService.roleService.hasRole('Security')" class="my-4"></nz-divider>
  <!-- Footer Buttons -->
  <div class="flex justify-between" *ngIf="!commonService.roleService.hasRole('Security')">
    <button [routerLink]="['/incidents']" (click)="close()" nz-button
      class="bg-primary text-white hover:bg-primary-hover border-none hover:text-white">
      All Incidents
    </button>
    <button *ngIf="!commonService.roleService.hasRole('Security')"
      [routerLink]="['/incidents/'+incidentDetails$?.incident_id]" (click)="close()" nz-button
      class="bg-primary text-white hover:bg-primary-hover hover:text-white">
      View Incident Details
    </button>
  </div>
</div>
