<div class="container-fluid desktop:h-[100dvh] desktop:overflow-hidden">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <!-- Left Section -->
        <div nz-col class="gutter-row justify-center m-auto h-full p-32" [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24"
            [nzXl]="8">
            <div class="flex justify-center">
                <img nz-image src="assets/svg/hezky_logo_V2.svg" alt="Hezky tech" class="logo" draggable="false">
            </div>
            <div class="flex justify-center">
                <p class="login_sub_title">Monitoring done<span class="underline underline-offset-8"><br>right</span>.
                </p>
            </div>
        </div>
        <!-- Right Section -->
        <div nz-col class="gutter-row flex flex-col justify-center min-h-screen background-container" [nzXs]="24"
            [nzSm]="24" [nzMd]="24" [nzLg]="24" [nzXl]="16" id="loginCard">
            <div class=" max-w-4xl mx-auto bg-white p-6">
                <!-- Header Section -->
                <div class="text-start mb-8">
                    <h1 class="text-3xl font-bold text-primary mb-6">About Us</h1>
                    <div class="space-y-4">
                        <p class="text-gray-600 text-lg">
                            We dare to imagine a world where efficiency takes priority, where everything and
                            everyone is connected.
                        </p>
                        <p class="text-gray-600 text-lg">
                            With our unique approach of keeping compliance and the end user in mind, we
                            deliver a healthy prognosis for your business helping you keep your operations
                            efficient, safe, and compliant.
                        </p>
                        <p class="text-gray-600 text-lg">
                            We provide visibility and analytics that delve deep into the performance of your
                            equipment, sites, and teams.
                        </p>
                    </div>
                </div>

                <!-- Values Section -->
                <div class="mt-12">
                    <h2 class="text-2xl font-bold text-primary mb-4">Values</h2>
                    <div class="grid gap-4">
                        <!-- Make it simple -->
                        <nz-card class="bg-white hover:shadow-md transition-shadow">
                            <div class="flex flex-col">
                                <h3 class="text-xl font-semibold text-gray-800 mb-2">
                                    Make it simple
                                </h3>
                                <p class="text-gray-600">
                                    We expect to do the work and deliver technical solutions to complex
                                    problems, simply.
                                </p>
                            </div>
                        </nz-card>

                        <!-- Increase efficiency -->
                        <nz-card class="bg-white hover:shadow-md transition-shadow">
                            <div class="flex flex-col">
                                <h3 class="text-xl font-semibold text-gray-800 mb-2">
                                    Increase efficiency
                                </h3>
                                <p class="text-gray-600">
                                    We imagine a world where everything and everyone is connected to reduce
                                    inefficiencies.
                                </p>
                            </div>
                        </nz-card>

                        <!-- Relentless iteration -->
                        <nz-card class="bg-white hover:shadow-md transition-shadow">
                            <div class="flex flex-col">
                                <h3 class="text-xl font-semibold text-gray-800 mb-2">
                                    Relentless iteration to find a better way
                                </h3>
                                <p class="text-gray-600">
                                    Our energy to learn new things and create solutions every day is
                                    unmatched anywhere in the world.
                                </p>
                            </div>
                        </nz-card>
                    </div>
                </div>
            </div>
            <div class="flex mb-3 justify-end px-5">
                    <a class="text-gray" [routerLink]="['/about-us']">About Us</a>
                    <span class="text-white mx-3">|</span>
                    <a class="text-white" [routerLink]="['/contact-us']">Contact Us</a>
                    <span class="text-white mx-3">|</span>
                    <a class="text-white" [routerLink]="['/terms-conditions']"> Terms & Conditions</a>
                    <span class="text-white mx-3">|</span>
                    <a class="text-white" [routerLink]="['/privacy-policy']"> Privacy policy</a>
            </div>
        </div>
    </div>
</div>