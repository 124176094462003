import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ConfirmModel } from '../../types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-generic-confirmation-dialog',
  templateUrl: './generic-confirmation-dialog.component.html',
  styleUrl: './generic-confirmation-dialog.component.scss'
})
export class GenericConfirmationDialogComponent {
  readonly nzModalData: ConfirmModel = inject(NZ_MODAL_DATA);
  form:FormGroup = new FormGroup({});
  protected isInvalid:boolean = false
  constructor(private fb: FormBuilder, private commonSvc:CommonService,protected modal:NzModalRef) {
    this.form.addControl('password', this.fb.control('',[Validators.required]));
    if(this.nzModalData?.extra?.type === 'DELETE' || this.nzModalData.for === 'REPORTS') {
      this.form.addControl('remarks', this.fb.control('',[Validators.required]));
    }
  }
  destroyModal(): void {
    this.modal.destroy()
  }
  submitForm() {
    if (!this.commonSvc.validate(this.form)) return;
    if (this.nzModalData.for === 'ALARM_PASSWORD') {
      if(this.nzModalData?.metaData) {
        this.commonSvc.httpSvc.post(environment.apiBaseUrl + this.nzModalData.metaData.api, { user_id: this.commonSvc.user_data.user_id, ...this.form.getRawValue() }).subscribe({
          next: (res: any) => {
            if (!res?.isVerified) this.isInvalid = true;
            if (res?.status === 200 && res?.isVerified) {
              if (this.nzModalData.extra?.type === 'EDIT' || this.nzModalData.extra?.type === 'SWITCH') {
                this.modal.destroy({ isVerified: res?.isVerified });
              }
              if (this.nzModalData.extra?.type === 'DELETE') {
                this.modal.destroy({ isVerified: res?.isVerified, remarks: this.form.get('remarks')?.value });
              }
            }
          }
        })
      }
    } else this.modal.destroy(this.form.getRawValue());
  }
}
