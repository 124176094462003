import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'relativeTime',
  pure: true,
})
export class RelativeTimePipe implements PipeTransform {
  transform(value: string | null, format: 'card' | 'modal' | 'default' = 'default'): string {
    if (!value) return 'N/A';
    const propDate = DateTime.fromSQL(value) || DateTime.fromISO(value);
    if (!propDate.isValid) return 'Invalid date';
    const now = DateTime.now();
    const isFuture = propDate > now;
    const diff = isFuture 
      ? propDate.diff(now, ['days', 'hours', 'minutes']).toObject() 
      : now.diff(propDate, ['days', 'hours', 'minutes']).toObject();

    let result = '';
    const labels = {
      day: format === 'card' ? 'D' : 'day',
      hour: format === 'card' ? 'H' : 'hour',
      minute: format === 'card' ? 'M' : 'minute',
    };

    if (diff.days && Math.floor(diff.days) >= 1) {
      result += `${Math.floor(diff.days)}${labels.day === 'day' ? ' ' : ''}${labels.day}${Math.floor(diff.days) > 1 && format !== 'card' ? 's' : ''} `;
    }
    if (diff.hours && Math.floor(diff.hours) >= 1) {
      result += `${Math.floor(diff.hours)}${labels.hour === 'hour' ? ' ' : ''}${labels.hour}${Math.floor(diff.hours) > 1 && format !== 'card' ? 's' : ''} `;
    }
    if (diff.minutes && Math.floor(diff.minutes) >= 1 && !result.includes(labels.minute)) {
      result += `${Math.floor(diff.minutes)}${labels.minute === 'minute' ? ' ' : ''}${labels.minute}${Math.floor(diff.minutes) > 1 && format !== 'card' ? 's' : ''} `;
    }
    if (!result) {
      result = isFuture ? 'In a moment' : 'Just now';
    }
    if (format === 'card') {
      result = `${isFuture ? 'In ' : 'since '}${result.trim()}`;
    } else if (format === 'modal') {
      result = `${isFuture ? 'In ' : 'since'} ${result.trim()} ${isFuture ? 'from now' : ''}`;
    } else if(format === 'default') {
      result += result !== 'Just now' ? ' ago' : ''; 
    }
    return result;
  }
}
