import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0',
    }),
  };

  /**
   * GET method
   * @param url API endpoint
   * @returns Observable<T>
   */
  get<T>(url: string, params?: any): Observable<T> {
    const options = { ...this.httpOptions, params };
    if (params) {
      options.params = params;
    }
    return this.http.get<T>(url, options).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * POST method
   * @param url API endpoint
   * @param body Request payload
   * @returns Observable<T>
   */
  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  postFile<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * PUT method
   * @param url API endpoint
   * @param body Request payload
   * @returns Observable<T>
   */
  put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(url, body, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * DELETE method
   * @param url API endpoint
   * @returns Observable<T>
   */
  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Error handling
   * @param error HttpErrorResponse
   * @returns Observable<never>
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Client error: ${error.error.message}`;
    } else {
      errorMessage = `Server error (code: ${error.status}): ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error('Something went wrong. Please try again later.'));
  }
}

