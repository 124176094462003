<div class="loading-container" *ngIf="isLoading" [@fadeInOut]>
    <div class="particles">
        <div *ngFor="let particle of particles" class="particle"
            [style.transform]="'translate3d(' + particle.x + 'px,' + particle.y + 'px, 0)'">
        </div>
    </div>
    <div class="content-wrapper flex items-center flex-col">
        <div class="loading-text flex flex-col items-center">
            <img src="assets/svg/hezky_logo_master.svg" width="25%" alt="Hezky tech" class="logo" draggable="false">
            <h2 class="title mt-2">
                <span *ngFor="let char of loadingTitle.split(''); let i = index" [style.animation-delay]="i * 0.1 + 's'">{{ char }}</span>
            </h2>
            <nz-spin nzSimple *ngIf="deepLinkingSvc.isLoading"></nz-spin>
        </div>
    </div>
</div>