// incident-modal.component.ts
import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CommonService } from '../../../shared/services/common.service';
import { IncidentManagementService } from '../incident-shared.service';

interface IncidentLog {
  date: string;
  time: string;
  message: string;
  severity?: 'High' | 'Medium' | 'Low';
  type?: 'acknowledgement' | 'update';
}

@Component({
  selector: 'app-incident-modal',
  templateUrl: './incident-modal.component.html',
  styleUrl: './incident-modal.component.scss'
})
export class IncidentModalComponent {
  incidentDetails$: any = [];
  users$: Array<any> = [];
  events: any[] = [];
  id: string;
  temp_pref: 'D' | 'F';
  status: any[] = [ { key: 'Active', value: 'ACTIVE' }, { key: 'Closed', value: 'CLOSED' }, ];
  apiCallLoaders = { isIncidentLogsLoading: false };
  incidentLogs: IncidentLog[] = [];
  popConfirmVisible?: NzModalRef;
  currentStep = 1; // Adjust based on current progress

  constructor(
    @Inject(NZ_MODAL_DATA) protected modalData: any,
    public modal : NzModalService,
    private modalRef: NzModalRef,
    public commonService: CommonService,
    public incidentManager: IncidentManagementService
  ) {
    this.id = modalData.incidentId.toString();
    this.temp_pref = this.commonService.temp_pref;
    this.init();
  }

  init(): void {
    this.status = this.incidentManager.status;
    this.loadData();
  }

  loadData() {
    this.getIncidentById();
    this.getUserList();
  }

  getIncidentById() {
    this.incidentManager.getIncidentById(this.id).subscribe(incidentDetails => {
      this.incidentDetails$ = incidentDetails;
      this.getIncidentAllEvents();
    });
  }

  getUserList() {
    this.incidentManager.getUserList().subscribe(users => {
      this.users$ = users;
    });
  }

  acknowledgeIncident() {
    this.incidentManager.acknowledgeIncident(
      this.incidentDetails$.incident_id,
      this.commonService.user_name,
    ).subscribe(() => {
      this.loadData();
    });
  }

  updateStatus(event: any): void {
    this.modal.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure you want to change the status of this incident?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.incidentManager
            .updateStatus(this.incidentDetails$.incident_id, event, this.commonService.user_name)
            .subscribe({
              next: () => {
                resolve('Success');
              }
            });
        }),
      nzOnCancel: () => {
        this.getIncidentById();
      },
    });
  }

  getUsernameById(user_id: number): string | undefined {
    const user = this.users$.find((u) => u.user_id === user_id);
    return user ? user.username : 'Select User';
  }

  getIncidentAllEvents() {
    this.apiCallLoaders.isIncidentLogsLoading = true;
    this.incidentManager.getIncidentEvents(this.id).subscribe(events => {
      this.events = events;
      this.apiCallLoaders.isIncidentLogsLoading = false;
    });
  }
  updateAssignTo(assigned_to: number): void {
    this.modal.confirm({
      nzTitle: 'Confirm',
      nzContent: `Are you sure you want to assign this incident to ${this.getUsernameById(assigned_to)}?`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.incidentManager
            .updateAssignTo(this.id, assigned_to, this.commonService.user_name)
            .subscribe({
              next: () => {
                this.loadData();
                resolve('Success');
              }
            });
        }),
      nzOnCancel: () => {
        this.getIncidentById();
      },
    });
  }


  close(): void {
    this.modalRef.close();
  }

  formatTemperature(value: number, temp_pref: 'D' | 'F'): string {
    return this.incidentManager.formatTemperature(value, temp_pref);
  }

  formatThreshold(value: number, temp_pref: 'D' | 'F'): string {
    return this.incidentManager.formatThreshold(value, temp_pref);
  }
  getEventClass(event: any): string {
    if (event?.status === 'ACTIVE') {
      switch (event?.severity) {
        case 'High':
          return 'activeHigh';
        case 'Medium':
          return 'activeMedium';
        case 'Low':
          return 'activeLow';
        default:
          return 'active';
      }
    } else if (event?.status === 'CLOSED') {
      return 'closed';
    }
    return 'active';
  }

  getContentClass(event: any): string {
    if (event?.status !== 'CLOSED') {
      switch (event?.severity) {
        case 'High':
          return 'sub_sec_high_color';
        case 'Medium':
          return 'sub_sec_medium_color';
        case 'Low':
          return 'sub_sec_low_color';
        default:
          return 'sub_sec_active_color';
      }
    }
    return 'sub_sec_active_color';
  }

}
