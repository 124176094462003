import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { API_ENDPOINTS } from '../../core/services/api.service';
import { CommonService } from '../../shared/services/common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  constructor(public commonSvc: CommonService) { }
  // INCIDENT DATA API CALLS
  getAllIncident(payload: any): Observable<any[]> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.LIST, payload);
  }
  getIncidentDetailsById(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.BY_ID.DATA,data);
  }
  getIncidentEvents(incident_id : any): Observable<any>{
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.BY_ID.LOGS, incident_id)
  }
  getIncidentHistory(incident_id : any): Observable<any>{
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.BY_ID.HISTORY, incident_id)
  }
  getIncidentGraphData(incident_id : any): Observable<any>{
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.BY_ID.GRAPH_DATA, incident_id)
  }
  // INCIDENT ACTION API CALLS
  updateIncidentAssignedTo(data: any): Observable<any> {
    return this.commonSvc.httpSvc.put<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.ASSIGN , data)
  }
  updateIncidentStatus(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.STATUS_UPDATE,data)
  }
  incidentAcknowledgement(data: any): Observable<any> {
    return this.commonSvc.httpSvc.put<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.ACKNOWLEDGE , data)
  }

  // FORM DATA API CALLS
  getAllIncidentFormData(incident_id : any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.BY_ID.FORM_DATA, incident_id)
  }
  getExcursionReasonList(requestData : any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.EXCURSION_LIST, requestData)
  }

  // Submit API CALLS
  submitIncidentExcDetails(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.SUBMIT_INC_DETAILS, data)
  }
  submitInvCapaDetails(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.SUBMIT_CAPA_DETAILS, data)
  }
  SubmitEvalDetails(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.SUBMIT_EVAL_DETAILS, data)
  }

  uploadCapaFile(formData: any): Observable<any> {
    return this.commonSvc.httpSvc.postFile<any>(`${environment.apiBaseUrl}${API_ENDPOINTS.INCIDENTS.FORM.UPLOAD_CAPA_FILE}`,formData)
  }
  downloadCapaFile(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.DOWNLOAD_CAPA_FILE, data )
  }


  // APPROVE_REJECT_API_CALLS
  approveRejectIncident(data: any){
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.ACTION.FIRST_TAB, data)
  }
  approveRejectCapa(data: any){
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.ACTION.SECOND_TAB, data)
  }
  approveRejectEvaluation(data: any){
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.ACTION.THIRD_TAB, data)
  }
  approveRejectFinal(data: any){
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.ACTION.FINAL_TAB, data)
  }

  saveComment(log:any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>( environment.apiBaseUrl + API_ENDPOINTS.INCIDENTS.FORM.COMMENT, log)
  }
}
