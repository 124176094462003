// incident-management.service.ts
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { IncidentService } from '../incidents/incident.service';
import { UsersService } from '../admin/user-management/users.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class IncidentManagementService {
  apiCallLoaders = { isIncidentLoading: false };
  status: any[] = [
    { key: 'Active', value: 'ACTIVE' },
    { key: 'Closed', value: 'CLOSED' },
  ];

  constructor(
    private incidentService: IncidentService,
    private ngZone: NgZone,
    private toaster: NzMessageService,
    private userService: UsersService
  ) {
  }

  getIncidentById(incident_id: string): Observable<any> {
    const requestData = {
      incident_id,
      timezone : this.userService.commonSvc.time_zone
    }
    this.apiCallLoaders.isIncidentLoading = true;
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        this.incidentService.getIncidentDetailsById(requestData).subscribe(
          (response: any) => {
            if (response?.status === 200 && response?.data?.length > 0) {
              this.ngZone.run(() => {
                this.apiCallLoaders.isIncidentLoading = false;
                observer.next(response.data[0]);
              });
            } else {
              this.ngZone.run(() => {
                this.apiCallLoaders.isIncidentLoading = false;
                this.toaster.info('No incident data found for the provided ID.', { nzDuration: 5000 });
                observer.error('No incident data found');
              });
            }
          }
        );
      });
    });
  }
  getUserList(): Observable<any> {
    let requestData: any = { ...this.incidentService.commonSvc.nLevelPayload };
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        this.userService.getAllUserByLocation(requestData).subscribe((response: any) => {
          if (response?.data && response?.status === 200) {
            const users = response.data.map((user: any) => ({
              ...user,
              full_name: `${user.first_name} ${user.last_name}`
            }));
            this.ngZone.run(() => {
              this.apiCallLoaders.isIncidentLoading = false;
              observer.next(users);
            });
          } else {
            this.ngZone.run(() => {
              this.apiCallLoaders.isIncidentLoading = false;
              observer.error('Failed to fetch users');
            });
          }
        });
      });
    });
  }

  acknowledgeIncident(incidentId: string, userName: string): Observable<any> {
    const requestData = {
      incident_id: incidentId,
      last_update_user: userName,
      ...this.incidentService.commonSvc.nLevelPayload,
    };
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        this.incidentService.incidentAcknowledgement(requestData).subscribe((response: any) => {
          if (response?.data && response?.status === 200) {
            this.ngZone.run(() => {
              this.toaster.success('Incident acknowledged successfully');
              observer.next(response);
            });
          } else {
            this.ngZone.run(() => {
              observer.error('Failed to acknowledge');
            });
          }
        });
      });
    });
  }
  updateStatus(incidentId: string, status: string, userName: string): Observable<any> {
    const requestData = {
      incident_id: incidentId,
      status: status,
      last_update_user: userName,
      ...this.incidentService.commonSvc.nLevelPayload,
    };
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        this.incidentService.updateIncidentStatus(requestData).subscribe({
          next: (response: any) => {
            this.ngZone.run(() => {
              if (response?.status === 200) {
                this.toaster.success('Incident Status Updated Successfully.', { nzDuration: 5000 });
                observer.next(response);
              } else {
                this.toaster.error('Unable to update status.', { nzDuration: 5000 });
                observer.error('Status update failed');
              }
              this.apiCallLoaders.isIncidentLoading = false;
            });
          }
        });
      });
    });
  }
  getIncidentEvents(incident_id: string): Observable<any> {
    const requestData = {
      incident_id: incident_id,
      ...this.incidentService.commonSvc.nLevelPayload
    };
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        this.incidentService.getIncidentEvents(requestData).subscribe({
          next: (response: any) => {
            if (response?.status === 200) {
              this.ngZone.run(() => {
                this.apiCallLoaders.isIncidentLoading = false;
                observer.next(response.data);
              });
            } else {
              this.ngZone.run(() => {
                this.apiCallLoaders.isIncidentLoading = false;
                this.toaster.info('No incident logs found.', { nzDuration: 5000 });
                observer.error('No logs found');
              });
            }
          }
        });
      });
    });
  }
  updateAssignTo(incidentId: string, assignedTo: number, userName: string): Observable<any> {
    const requestData = {
      incident_id: incidentId,
      assigned_to: assignedTo,
      last_update_user: userName,
      ...this.incidentService.commonSvc.nLevelPayload,
    };
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        this.incidentService.updateIncidentAssignedTo(requestData).subscribe(
          (response: any) => {
            if (response?.status === 200) {
              this.ngZone.run(() => {
                this.toaster.success('Incident Assigned Successfully.', { nzDuration: 5000 });
                observer.next(response);
                this.getIncidentEvents(incidentId)
              });
            } else {
              this.ngZone.run(() => {
                this.toaster.info(response?.message || 'Failed to assign incident.', { nzDuration: 5000 });
                observer.error('Assignment failed');
              });
            }
          }
        );
      });
    });
  }
  formatTemperature(value: number, temp_pref: 'D' | 'F'): string {
    return temp_pref === 'F' ? `${(value * 1.8 + 32).toFixed(1)}℉` : `${value}°C`;
  }
  formatThreshold(value: number, temp_pref: 'D' | 'F'): string {
    return temp_pref === 'F' ? `${(value * 1.8 + 32).toFixed(1)}℉` : `${value}°C`;
  }
}