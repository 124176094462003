import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../../../core/services/api.service';
import { CommonService } from '../../../shared/services/common.service';
import { environment } from '../../../../environments/environment';
import { Observable, of, tap } from 'rxjs';
// import { simpleEncrypt, simpleDecrypt } from '../../../state/meta-reducer';
import { Settings } from '../../../shared/types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    public commonSvc: CommonService,
    private http: HttpClient
  ) { }

  getAllUserByLocation(location: any): Observable<any> {
    const storageKey = `usersByLocation_${location}`;
    const encryptedData = localStorage.getItem(storageKey);
    // if (encryptedData) {
    //   const decryptedData = JSON.parse(simpleDecrypt(encryptedData));
    //   return of(decryptedData);
    // }
    return this.commonSvc.httpSvc
      .post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.LIST_BY_LOCATION, location)
      // .pipe(
      //   tap(response => {
      //     const encryptedResponse = simpleEncrypt(JSON.stringify(response));
      //     localStorage.setItem(storageKey, encryptedResponse);
      //   })
      // );
  }

  getAllUserByCustomer(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.LIST_BY_CUSTOMER, data)
  }

  getRolesandLocations(data :any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.ROLES_AND_LOCATIONS, data)
  }

  getUserById(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.DATA_BY_ID, data)
  }

  addUser(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.CREATE, data)
  }

  updateUser(data: any): Observable<any> {
    return this.commonSvc.httpSvc.put<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.UPDATE, data)
  }

  deleteUser(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.DEACTIVATE, data)
  }
  activeUser(data: any): Observable<any> {
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.ACTIVATE, data)
  }
  resetPassword(data: any): Observable<any>{
    return this.commonSvc.httpSvc.post<any>(environment.apiBaseUrl + API_ENDPOINTS.ADMIN.USER_MANAGEMENT.RESET_PASSWORD, data)

  }
  // ACCOUT SETTINGS APIS
  updateSettings(settings: Settings): Observable<any> {
    return this.commonSvc.httpSvc.put<any>(environment.apiBaseUrl + API_ENDPOINTS.PROFILE.SETTINGS, settings)
    //return this.http.put(`http://localhost:3000/api/user/update-setting`, settings);
  }
  updateProfile(data: any):Observable<any> {
    return this.commonSvc.httpSvc.put<any>(environment.apiBaseUrl + API_ENDPOINTS.PROFILE.UPDATE_USERNAME, data)
    //return this.http.put(`http://localhost:3000/api/user/update-username`, data);
  }
}
