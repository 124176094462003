<form nz-form [nzLayout]="'vertical'" [formGroup]="form">
    <nz-form-item>
        <nz-form-label nzFor="password" [nzSpan]="12">Password</nz-form-label>
        <nz-form-control [nzErrorTip]="passwordError">
            <input nz-input type="password" name="password" id="password" formControlName="password" />
        </nz-form-control>
        <ng-template #passwordError let-control>
            @if (control.errors?.['required']) { Required! }
        </ng-template>
    </nz-form-item>
    <nz-form-item *ngIf="this.nzModalData?.extra?.type === 'DELETE' || nzModalData.for === 'REPORTS'">
        <nz-form-control [nzErrorTip]="remarksError">
            <nz-form-label nzFor="password" [nzSpan]="12">Remarks</nz-form-label>
            <nz-textarea-count [nzMaxCharacterCount]="100">
                <textarea class="w-full" placeholder="Enter remarks" formControlName="remarks" [maxlength]="100"
                    rows="3" nz-input></textarea>
            </nz-textarea-count>
        </nz-form-control>
        <ng-template #remarksError let-control>
            @if (control.errors?.['required']) { Required! }
        </ng-template>
    </nz-form-item>
</form>
<nz-alert *ngIf="isInvalid" nzShowIcon nzType="error" nzMessage="Invalid Password" ></nz-alert>
<div *nzModalFooter>
    <button nz-button nzType="default" type="button" (click)="destroyModal()">Cancel</button>
    <button nz-button nzType="primary" type="submit" (click)="submitForm()">Submit</button>
</div>