export interface UserData {
    admin_id: number,
    contact: string,
    customer: { customer_id: number, customer_name: string },
    email: string,
    first_name: string,
    is_active: boolean,
    last_name: string,
    temp_preference: string,
    timezone: string,
    userRoles: { role_id: number, role_name: string, privilege: number },
    user_id: number,
    notificationCount : number
}
export interface NzTreeNode {
    level: number;
    title: string;
    key: string;
    children?: NzTreeNode[];
    isLeaf?: boolean;
    expanded?: boolean;
    extraData?:any;
    formData?:any,
    ancestors?: any[];
    type: 'location' | 'group';
    parent?: any;
    expandable: boolean; // Add this property
}
export interface FlatNode {
    title: string;
    key: string;
    isLeaf?: boolean;
    children?: FlatNode[];
    expandable: boolean;
    level: number;
}
export interface SpocDetail {
    name: string;
    email: string;
    contact: string;
    designation: string;
}

export interface Location {
    location_id?: number;
    registered_name: string;
    ancestors: string[];
    spoc_detail: SpocDetail[];
    display_name: string;
    registered_address: string | null;
    customer_id: number;
    is_deleted: boolean;
}

export interface ConfirmModel {
    metaData?: { api: string, method: string},
    extra?: any,
    for?:string
}
export interface Settings {
    user_id?: number;
    temp_preference?: string;
    timeZone_preference?:string;
}

export enum ReportType {
    device = 'device_report',
    audit = 'audit_log_report',
    asset = 'asset_summary_report',
    incident = 'incident_summary_report',
    daily = 'daily_summary_report',
}