import { Injectable } from '@angular/core';

interface UserRole {
  role_id: number;
  role_name: string;
  privilege: number | number[]; // Allow privilege to be a number or an array of numbers
}

@Injectable({
  providedIn: 'root'
})
export class RoleAuthorizationService {
  private userRole: UserRole | null = null;

  constructor() {}

  setUserRole(userRole: UserRole): void {
    this.userRole = userRole;
    sessionStorage.setItem('userRoles', JSON.stringify(userRole));
  }

  getPrivilege(): number | number[] | null {
    return this.userRole ? this.userRole.privilege : null;
  }

   getRoleName(): string | null {
    return this.userRole ? this.userRole.role_name : null;
  }

  hasAccess(allowedPrivileges: number[]): boolean {
    if (!this.userRole) return false;
  
    const { privilege } = this.userRole;
    if (Array.isArray(privilege)) {
      return privilege.some((p) => allowedPrivileges.includes(p));
    }
    return allowedPrivileges.includes(privilege);
  }

  isSuperAdmin(): boolean {
    return this.userRole?.role_name === 'SuperAdmin' && this.hasAccess([1]);
  }
  
  isAdmin(): boolean {
    return this.userRole?.role_name === 'Admin' && this.hasAccess([2]); 
  }
  
  isViewer(): boolean {
    return this.hasAccess([3]); 
  }

  hasRole(roleName: string): boolean {
    return this.userRole?.role_name === roleName;
  }
  
  canPerformAction(requiredPrivilege: number): boolean {
    return this.hasAccess([requiredPrivilege]); // Wrap privilege in an array
  }
  
}
