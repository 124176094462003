export const API_ENDPOINTS = {
    AUTH: {
        LOGIN: 'auth/user-sign-in',
        VERIFY_FORGET_EMAIL: 'user/check-user-emailForSendOtp',
        VERIFY_FORGET_OTP: 'user/verify-otp-at-forgot-pass',
        RESEND_FORGET_OTP: 'user/resend-otp-at-forgot-email',
        TF_OTP: 'auth/user-verify-otp',
        RESEND_TF_OTP: 'auth/user-resend-otp',
        UPDATE_PASSWORD: 'user/update-password-v2',
        VERIFY_PASSWORD: 'incident/comparePassword',
    },
    USERS: {
        LIST: 'get-all-users',
        DETAILS: (userId: string) => `/users/${userId}`,
    },
    ASSETS: {
        LIST: 'asset/get-all-active-asset_v2',
        LIST_BY_LOCATION: 'asset/get-asset-by-location',
        FILTER_LIST: 'asset/get-all-filter',
        ADD_ASSET: 'asset/add-assets_v2',
        UPDATE_ASSET: 'asset/update-asset_v2',
        ENVIRONMENT_BY_LOCATION_ID: 'asset/get-asset-env',
        ENVIRONMENT_BY_CUSTOMER_ID: 'env/get-all-env',
        MAKE_BY_LOCATION_ID: 'asset/get-asset-make',
        MODEL_BY_LOCATION_ID: 'asset/get-asset-model',
        TAG_BY_LOCATION_ID: 'asset/get-asset-tag',
        GET_ASSET_BY_ID: (id: string) => `asset/get-asset-by-id_v2/${id}`,
        TODAYS_GRAPH_DATA : 'asset/get-today-graph-data_v2',
        YESTERDAY_GRAPH_DATA : 'asset/get-yesterday-graph-data_v2',
        WEEK_GRAPH_DATA : 'asset/get-week-graph-data_v2',
        MONTH_GRAPH_DATA : 'asset/get-month-graph-data_v2',
        GET_GRAPH_DATA_BY_DATE_RANGE : 'asset/get-graph-between-date-data_v2',
        GET_ALL_SENSOR_CERTIFICATE: 'admin/getAllCertificates',
        SWAP_SENSOR: 'sensor/swap-sensor_v2',
        ALARM: {
            GET_ALARMS_BY_ASSET: 'alarm/get-alarm-by-asset_v2',
            ADD_ALARM: 'alarm/add-alarm_v2',
            UPDATE_ALARM: 'alarm/update-alarm_v2',
            DELETE_ALARM: 'alarm/delete-alarm_v2',
            ENABLE_DISABLE_ALARM: 'alarm/alarm-enable-disable',
        }
    },
    INCIDENTS: {
        LIST: 'incident/get-all-incident',
        ACKNOWLEDGE: 'incident/incident-acknowledgedment',
        ASSIGN: 'incident/update-incident-assigned',
        STATUS_UPDATE: 'incident/update-status',
        BY_ID: {
            DATA: 'incident/get-incident-by-id',
            HISTORY: 'incident/get-incident-log',
            FORM_DATA: 'admin/get-incidentTabs-data',
            LOGS: 'incident/get-incident-detail-log',
            GRAPH_DATA: 'incident/get-incidentGraph-dateData'
        },
        FORM: {
            EXCURSION_LIST: 'admin/get-excursion-reason-list',
            SUBMIT_INC_DETAILS: 'incident/add-affected-product-list',
            SUBMIT_CAPA_DETAILS: 'incident/add-invCapaDetails',
            SUBMIT_EVAL_DETAILS: 'incident/add-evalDispDetails',
            UPLOAD_CAPA_FILE: 'admin/upload-capa-doc',
            DOWNLOAD_CAPA_FILE: 'admin/download-capa-file',
            COMMENT : 'incident/add-comments',
            ACTION: {
                FIRST_TAB: 'incident/firstTabAction',
                SECOND_TAB: 'incident/secondTabAction',
                THIRD_TAB: 'incident/thirdTabAction',
                FINAL_TAB: 'incident/finalTabAction'
            }
        }
    },
    REPORTS: {
        DEVICE_REPORT: 'reports/get-device-report',
        INCIDENT_REPORT: 'reports/get-incident-summary-report',
        AUDIT_REPORT: 'reports/get-audit-summary-report',
        ASSET_REPORT: 'reports/get-asset-summary-report',
        DAILY_REPORT: 'reports/get-daily-summary-report',
        SIGN_AND_SUBMIT_REPORT: 'report/signAndSubmitReport',
        MY_REPORTS: 'report/getMyReport',
        ALL_REPORTS: 'report/getAllReports',
        SCHEDULE_REPORTS: 'scheduler/getReport',
        CREATE_SCHEDULE: 'scheduler/addReport',
        DELETE_SCHEDULE_REPORT:'scheduler/deleteReport'
    },
    ADMIN: {
        GROUP_MANAGEMENT: {
            ADD_LOCATION: 'groupzone/addLocation',
            UPDATE_LOCATION: 'groupzone/updateLocation',
        },
        PRODUCT_MANAGEMENT: {
            LIST: 'product/get-all-product-data',
            DATA_BY_ID: 'product/get-product-by-id',
            CREATE: 'product/add-product',
            UPDATE: 'product/updateProductById',
            DELETE: 'product/deleteProductById'
        },
        USER_MANAGEMENT: {
            LIST_BY_CUSTOMER: 'user/getalluserbyCust',
            LIST_BY_LOCATION: 'user/getUserByLocation',
            DATA_BY_ID: 'user/get-user-by-id',
            CREATE: 'user/add-user',
            UPDATE: 'user/update-user_v2',
            DEACTIVATE: 'user/delete-user',
            ACTIVATE: 'user/activateUser',
            RESET_PASSWORD: 'admin/reset-password',
            ROLES_AND_LOCATIONS: 'role/getRolesAndLocations'
        },
        DEVICE_MANAGEMENT: {
            AVAILABLE_DEVICES: 'sensor/get-all-whitelistingData',
            GATEWAY: {
                LIST: 'gateway/get-gateway',
                DATA_BY_ID: 'gateway/get-gateway-by-id',
                CREATE: 'gateway/add-gateway',
                UPDATE: 'gateway/update-gateway',
                DELETE: 'gateway/delete-gateway',
                LOGS: 'device/device-logs'
            },
            SENSOR : {
                LIST_BY_ASSET_ID: 'sensor-data/getSensorbyAssetID',
                LIST: 'sensor/get-all-active-sensor-by-group-id',
                DATA_BY_ID: 'sensor/get-sensor-by-id',
                CREATE: 'sensor/add-sensor_v2',
                UPDATE: 'sensor/update-sensor_v2',
                DELETE: 'sensor/delete-sensor-by-id',
                LOGS: 'device-logs'
            },
            HOOTER: {
                LIST: 'sensor/get-all-active-hooter-by-group-id',
                DATA_BY_ID: 'hooter/get-hooter-by-id',
                CREATE: 'hooter/add-hooter',
                UPDATE: 'hooter/update-hooter-by-id',
                DELETE: 'hooter/delete-hooter-by-id'
            }
        },
    },
    NOTIFICATION : {
        GET_ALL : 'notification/getAllNotification',
        MARK_READ : 'notification/updateNotification',
    },
    LOCATION: {
        LOCATION_BY_USER_ID: 'groupzone/getNlevelByUserId',
    },
    UTILITY : {
        COMPARE_PASSWORD : 'incident/comparePassword',
    },
    DASHBOARD: {
        GET_ASSETS_WITH_SENSORS: 'asset/get-asset-data-with-sensor',
        GET_ACTIVE_INCIDENT: 'incident/get-all-incident'
    },
    PROFILE: {
        ADD_SUPPORT_TICKET: 'support/addSupportTicket',
        GET_RECENT_TICKET: 'support/getSupportTicket',
        UPDATE_USERNAME : 'user/update-username',
        SETTINGS : 'user/update-setting',
        USER_LOG: 'user/adduserLog',
        DEMO_VIDEOS: 'demovideos/getDemoVideo'
    },
    CUSTOMER: {
        GET_CUSTOMER_BY_ID: 'admin/get-customer-by-id'
    }
}
