<div class="min-h-screen bg-gray-50 py-3">
    <div class="px-4">
        <nz-card class="mb-8">
            <nz-page-header class="mb-2 pl-2 pr-8">
                <nz-page-header-title>
                    <h1 class="text-3xl font-semibold text-center text-primary">Privacy Policy</h1>
                </nz-page-header-title>
                <nz-page-header-extra>
                    <nz-space>
                        <img nz-image class="" src="assets/svg/hezky_logo_V2.svg" alt="Hezky tech" width="60px"
                            draggable="false">
                    </nz-space>
                </nz-page-header-extra>
            </nz-page-header>
            <p class="text-gray-700 mb-4">
                This document is an electronic record in terms of the Information Technology Act, 2000 ("IT Act") and
                rules thereunder, and is published in accordance with the provisions of Rule 3 (1) of the Information
                Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules 2021, which mandates for
                publishing of rules and regulations, privacy policy and terms of use for access or usage of the Website.
                This electronic record is generated by a computer system and does not require any physical or digital
                signatures.
            </p>
            <p class="text-gray-700 mb-4">
                Hezkytech LLP and its subsidiaries and affiliates (hereinafter, "Hezky" "YODA,") provide both cloud
                based software services ("Cloud Services") and technology based products ("Technology Products"). Our
                Technology Products may collect and transmit information to one of our cloud platforms to enable us to
                deliver the service that you have subscribed to.
            </p>
            <p class="text-gray-700 mb-4">
                We know that your privacy is important to you. We created this Privacy Policy to let you know what
                Personal Information we collect, how we use and share the Personal Information we collect, and how we
                protect your privacy. The Privacy Policy applies to your use of this website (the "Site") and all
                Products, except those Products that have a separate Privacy Policy.
            </p>
            <p class="text-gray-700 mb-4">
                These Terms and Conditions / Terms of Service ("Terms" / TOS / Website Terms) read with the privacy
                policy, disclaimers, rules of Hezkytech LLP as may be posted on this Website, having domain name
                www.hezkytech.com, from time to time, shall govern your use / access of the Website, ("Website"), its
                related content, sites and hyperlinks owned maintained and operated by Hezkytech LLP Hezkytech Infocomm
                Ltd. and or its Affiliates and the domain name is owned by one of its Affiliates. These Terms applies to
                all Users of the Website.
            </p>
            <nz-divider></nz-divider>
            <h2 class="text-xl font-semibold mb-4 text-primary">YOUR CONSENT</h2>
            <p class="text-gray-700 mb-4">
                Please read this Privacy Policy carefully before using this Site and our Products. By using this Site or
                our Products, you are consenting to the collection, use and disclosure of your Personal Information as
                set forth in this Privacy Policy. If you do not agree to any of the terms of this Privacy Policy, then
                you may not access or use this Site or our Products.
            </p>
            <nz-divider></nz-divider>
            <h2 class="text-xl font-semibold mb-4 text-primary">Changes to the Website Terms</h2>
            <nz-alert nzType="info"
                nzMessage="We may change, modify, amend or update the Website Terms from time to time by publishing an updated version on the Website. By continuing to use the Website you will be deemed to accept the updated Website Terms and agree to be bound by them."
                class="mb-6"></nz-alert>
            <h2 class="text-xl font-semibold mb-4 text-primary">Definitions</h2>
            <nz-collapse class="mb-6">
                <nz-collapse-panel nzHeader="Key Terms" [nzActive]="true">
                    <ul class="list-disc pl-6 space-y-3">
                        <li><span class="font-semibold">"Affiliate(s)"</span> - shall mean with respect to Hezkytech
                            LLP, any person directly or indirectly controlling, controlled by, or under direct or
                            indirect common control with Hezkytech LLP including its subsidiaries and associate
                            companies.</li>
                        <li><span class="font-semibold">"Agreement"</span> - means the Terms and Conditions / Terms of
                            Service, as detailed herein including all schedules, appendices, annexures, privacy policy,
                            Disclaimer and will include the references to this Agreement as amended, novated,
                            supplemented, varied or replaced from time to time.</li>
                        <li><span class="font-semibold">"Hezkytech LLP"</span> - / "we"/ "us"/ "our" shall mean
                            Hezkytech LLP, having its registered office [.] Maharashtra and or its Affiliates, service
                            providers, successors and assigns, wherever the context applies.</li>
                        <li><span class="font-semibold">"Content"</span> - means all text, graphics, user interfaces,
                            visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code
                            collectively.</li>
                        <li><span class="font-semibold">"Product(s)"</span> - shall mean mobile handsets, Hezkytech
                            devices, gateway routers, Sensors, Humidity Sensors, GPS Sensors, Temperature Sensors, Light
                            Sensors, Leak Detection sensors, magnetic contact sensors, ultrasonic sensors, motion
                            detector sensors, telecom equipment, network interface units (NIU), USB modems, data cards,
                            accessories, spares & supplies, consumer electronic goods etc.</li>
                        <li><span class="font-semibold">"Seller(s)"</span> - shall mean the entities including
                            manufacturer of the products which sells the products to the User directly thought the
                            Hezkytech.com website that includes Hezkytech LLP group companies / subsidiaries.</li>
                        <li><span class="font-semibold">"Service(s)"</span> - shall mean all services that Hezkytech LLP
                            provides to its subscribers through the website login and software.</li>
                        <li><span class="font-semibold">"You"/ "User"</span> - shall mean the end user accessing the
                            Website, its contents and or using the Services offered through the Website and includes a
                            subscriber of and digital services of Hezkytech LLP.</li>
                        <li><span class="font-semibold">"WebSite"</span> - means the website operated by Hezkytech LLP
                            (www.hezkytech.com), or its Affiliates, which provides information related to various
                            Services of Hezkytech LLP to the subscribers or any prospective customers.</li>
                    </ul>
                </nz-collapse-panel>
            </nz-collapse>
            <h2 class="text-xl font-semibold mb-4 text-primary">Registration</h2>
            <nz-card class="bg-gray-50 mb-6">
                <p class="text-gray-700 mb-4">
                    In order to avail the Services through the Website, you are required to register on our Website,
                    which may involve without limitation, setting up of a profile, providing your Software, or Hezkytech
                    ID / username and password, your name and other personally identifiable information. However you may
                    be able to lodge a web based complaint, feedback or suggestion, by filling the necessary information
                    and without registration.
                </p>
                <p class="text-gray-700 mb-4">
                    We may access your SOFTWARE PLATFORM or request you to enter other details such as email id etc.
                    along with the SOFTWARE PLATFORM. You will be solely responsible to ensure confidentiality of your
                    username or passwords or account with the Website.
                </p>
            </nz-card>
            <h2 class="text-xl font-semibold mb-4 text-primary">Types of Personal Information We Collect</h2>
            <nz-tabset class="mb-6">
                <nz-tab nzTitle="Information You Volunteer">
                    <div class="p-4">
                        <p class="text-gray-700">We collect the Personal Information you and others knowingly and
                            voluntarily provide when you use this Site or our services. For example, we collect the
                            Personal Information you provide when you subscribe to our emails, subscribe to a Cloud
                            Service, request technical support, attend a webinar, or contact us with questions.</p>
                    </div>
                </nz-tab>
                <nz-tab nzTitle="Browser & Device Information">
                    <div class="p-4">
                        <p class="text-gray-700">We collect information that is sent to us automatically by your web
                            browser or mobile device, or if applicable, the information that is sent to us by a Product
                            if used in conjunction with one of our cloud services. This information typically includes
                            your IP address, your Product's IP address, your Product's GPS location, the name of your
                            operating system, the name and version of your browser and usage information.</p>
                    </div>
                </nz-tab>
                <nz-tab nzTitle="Location Information">
                    <div class="p-4">
                        <p class="text-gray-700">Using GeoIP technology, and with your opt-in consent, we may also
                            collect precise, real-time location information from your mobile device and use that
                            information to deliver tailored content through this Site or to confirm that you are where
                            you say you are.</p>
                    </div>
                </nz-tab>
                <nz-tab nzTitle="Payment Information">
                    <div class="p-4">
                        <p class="text-gray-700">If you make a purchase, we collect personal information in connection
                            with the purchase. This information includes payment information, such as your credit or
                            debit card number and other card information; other account and authentication information;
                            and billing, shipping, and contact details.</p>
                    </div>
                </nz-tab>
                <nz-tab nzTitle="Third Party Information">
                    <div class="p-4">
                        <p class="text-gray-700">We may collect information from third parties, including business
                            partners and marketing agencies. This includes your contact information from marketing
                            partners when we engage in joint marketing or co-branding activities.</p>
                    </div>
                </nz-tab>
            </nz-tabset>
            <h2 class="text-xl font-semibold mb-4 text-primary">Price and Payment Policy</h2>
            <nz-card class="bg-gray-50 mb-6">
                <p class="text-gray-700 mb-4">
                    For the Services which are on chargeable basis Hezkytech LLP may offer you multiple payment options
                    online for availing the Services. However Hezkytech LLP reserves its right to add or delete any mode
                    of payment without any prior notice.
                </p>
                <ul class="list-disc pl-6 space-y-3 mb-4">
                    <li>We accept payments made by most of the major credit/debit cards issued in India</li>
                    <li>All payments must be in Indian Rupees</li>
                    <li>International cards are not accepted</li>
                    <li>Online payment modes include credit cards, debit card, net banking, UPI, etc.</li>
                </ul>
            </nz-card>
            <h2 class="text-xl font-semibold mb-4 text-primary">No Unlawful, Infringing or Offensive Activity</h2>
            <nz-alert nzType="warning"
                nzMessage="Your use of this Website must comply with all applicable laws including but not limited to Information Technology Act, 2000 and the Rules made thereunder. You must not post/transmit or enable another person to post/transmit via the Website any information or material which breaches any laws or regulations."
                class="mb-6"></nz-alert>
            <h2 class="text-xl font-semibold mb-4 text-primary">No Viruses or Other Interference</h2>
            <nz-card class="bg-gray-50 mb-6">
                <p class="text-gray-700 mb-4">You must not transmit or enable another person to transmit via the Website
                    any virus or other information or material or otherwise use the Website in a way which:</p>
                <ul class="list-disc pl-6 space-y-3">
                    <li>Tampers with, hinders the operation of or makes unauthorized modifications to the Website</li>
                    <li>Inhibits any other user from using the Website</li>
                    <li>Defames, harasses, threatens, menaces or offends any person</li>
                    <li>Contains obscene, indecent, inflammatory or pornographic material</li>
                </ul>
            </nz-card>
            <h2 class="text-xl font-semibold mb-4 text-primary">Intellectual Property</h2>
            <nz-collapse class="mb-6">
                <nz-collapse-panel nzHeader="Copyright and Trademarks" [nzActive]="true">
                    <div class="space-y-4">
                        <p class="text-gray-700">All intellectual property rights in this web site, design, text,
                            graphics, applications, software, underlying source code and all other aspects belong to us
                            and or our Affiliates.</p>
                        <p class="text-gray-700">The trademark 'Hezkytech LLP', its logo, trade mark 'Hezkytech', its
                            logo, and other Services Marks and logos owned by and/or licensed to Hezkytech LLP and/or
                            its Affiliates.</p>
                    </div>
                </nz-collapse-panel>
            </nz-collapse>
            <h2 class="text-xl font-semibold mb-4 text-primary">Information Security</h2>
            <nz-alert nzType="info"
                nzMessage="We are committed to safeguard the security and confidentiality of any information you provide to us. We understand that you accept the inherent security implications of providing information over the internet or world wide web and will not hold us responsible for any breach of security or disclosure of personal information."
                class="mb-6"></nz-alert>
            <h2 class="text-xl font-semibold mb-4 text-primary">Limitation of Liability</h2>
            <nz-card class="bg-gray-50 mb-6">
                <p class="text-gray-700 mb-4">
                    In no event Hezkytech LLP including affiliates and their respective owners, directors, officers,
                    agents, shareholders and partners shall be liable for any loss or damage whatsoever including
                    without limitation direct, indirect, punitive, incidental and consequential damages, lost profits,
                    or damages resulting from the use or inability to use the Website.
                </p>
                <p class="text-gray-700">
                    Hezkytech LLP including affiliates and their respective partners aggregate and total liability
                    (whether in contract, tort, negligence, under an indemnity, or by statute or otherwise) shall not
                    exceed INR 5,000/- (Rupees Five Thousand) or the last payment made by the user; whichever is lesser,
                    subject to applicable law and awarded by the competent court.
                </p>
            </nz-card>
            <h2 class="text-xl font-semibold mb-4 text-primary">Grievance Redressal</h2>
            <nz-descriptions [nzBordered]="true" class="mb-6">
                <nz-descriptions-item nzTitle="Grievance Officer" [nzSpan]="3">Varun Visawadia</nz-descriptions-item>
                <nz-descriptions-item nzTitle="Contact Email"
                    [nzSpan]="3">varun&#64;hezkytech.com</nz-descriptions-item>
            </nz-descriptions>
            <h2 class="text-xl font-semibold mb-4 text-primary">Governing Law and Jurisdiction</h2>
            <nz-alert nzType="info"
                nzMessage="If a dispute arises regarding these terms of use, the laws of the Republic of India will apply and shall be subject to the exclusive jurisdiction of courts in Mumbai, India."
                class="mb-6"></nz-alert>
            <nz-divider></nz-divider>
            <footer class="text-center text-gray-600 mt-8">
                <p class="mb-2">Last Updated: 1st September 2022</p>
                <p>&copy; Hezkytech LLP., All rights reserve
            </footer>
        </nz-card>
    </div>
</div>