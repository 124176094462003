import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { clearLocations, loadLocationsSuccess, loadLocationTreeStructure, loadTreeLocationsSuccess } from './location-tree.actions';

export interface LocationState {
    location: number[] | null;
    tree_location: string[] | null;
    tree_location_structure: string[] | null;
}

export const initialState: LocationState = {
    location: null,
    tree_location: null,
    tree_location_structure: null
};

export const locationFeature = createFeature({
    name: 'location',
    reducer: createReducer(
        initialState,
        on(loadLocationsSuccess, (state, { location }) => ({ ...state, location: location })),
        on(loadTreeLocationsSuccess, (state, { tree_location }) => ({ ...state, tree_location: tree_location })),
        on(loadLocationTreeStructure, (state, { tree_location_structure }) => ({ ...state, tree_location_structure: tree_location_structure })),
        on(clearLocations, () => ({ ...initialState })),
    ),
});

export const { name, reducer, selectLocationState } = locationFeature;

export const selectLocation = createSelector(selectLocationState, (state) => state.location);
export const selectTreeLocation = createSelector(selectLocationState, (state) => state.tree_location);
export const selectTreeLocationStructure = createSelector(selectLocationState, (state) => state.tree_location_structure);