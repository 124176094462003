<div class="min-h-screen bg-gray-50 py-3">
    <nz-card class="px-4 mx-4">
        <div class="text-center mb-8">
            <h1 class="text-2xl font-bold mb-2">Terms and Conditions</h1>
            <p class="text-gray-600">Hezkytech software, Website, Application</p>
        </div>

        <div class="space-y-6">
            <!-- General Section -->
            <nz-collapse>
                <nz-collapse-panel nzHeader="General" [nzActive]="true">
                    <div class="space-y-4">
                        <p class="text-gray-700">
                            This document is an electronic record in terms of The Information Technology Act, 2000 ("IT
                            Act") and rules thereunder, and is published in accordance with the provisions of Rule 3 (1)
                            of the Information Technology (Intermediaries Guidelines) Rules 2011, which mandates for
                            publishing of rules and regulations, privacy policy and terms of use for access or usage of
                            the Application.
                        </p>
                        <p class="text-gray-700">
                            These Terms and Conditions / Terms of Service ("Terms"/TOS/) read with the Privacy Policy,
                            disclaimers, rules of Hezkytech LLP, shall govern your use / access of this Application /
                            Software (Hezkytech Software Platform / Application).
                        </p>
                        <p class="text-gray-700">
                            By Logging into Hezkytech Software Platform or in App or accessing the Services, impliedly
                            or expressly accepting these Terms, Privacy Policy, You also accept and agree to be bound by
                            rules, policies and Terms of Hezkytech LLP for use of the Services and Hezkytech Software
                            Platform , as may be modified from time to time, and corresponding hyperlinks if any. If you
                            do not agree to be bound by the TOS please do not access Hezkytech Software Platform or
                            avail any Services provided through Hezkytech Software Platform .
                        </p>
                    </div>
                </nz-collapse-panel>

                <!-- Changes Section -->
                <nz-collapse-panel
                    nzHeader="Changes to the Terms and Conditions of usage of Hezkytech Software Platform">
                    <div class="space-y-4">
                        <p class="text-gray-700">
                            We may change, modify, amend or update the Terms from time to time by publishing an updated
                            version on Hezkytech Software Platform or by posting a modified or amended terms and
                            conditions on the Website
                            <a href="www.hezkytech.com" class="text-blue-600 hover:text-blue-800">www.hezkytech.com</a>
                        </p>
                    </div>
                </nz-collapse-panel>

                <!-- Definitions Section -->
                <nz-collapse-panel nzHeader="Definitions">
                    <div class="space-y-4">
                        <p class="text-gray-700 mb-4">For the purpose of this TOS:</p>
                        <nz-list [nzBordered]="true">
                            <nz-list-item>
                                <span class="font-semibold">Affiliate(s):</span>
                                <span class="ml-2">shall mean with respect to Hezkytech LLP, any person directly or
                                    indirectly controlling, controlled by, or under direct or indirect common control
                                    with, HEZKYTECH LLP, including its subsidiaries and associate companies.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Application:</span>
                                <span class="ml-2">means the Hezkytech Software Platform / mobile software application
                                    that HEZKYTECH LLP has permitted you to download, access and to which the TOS is
                                    linked and includes the TOS attached to it.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Content:</span>
                                <span class="ml-2">shall mean and include the files, media, videos, information, music,
                                    images, sound, text, any feedback, or content posted or provided by you.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Customer(s):</span>
                                <span class="ml-2">means a HEZKYTECH LLP subscriber or customer who has subscribed or
                                    been given access through the login ID and password with a valid and authorised
                                    subscription through HEZKYTECH LLP and has validly registered for availing the
                                    Service(s). 'Customer' includes 'you', the end user accessing the Application
                                    wherever the context applies.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Device:</span>
                                <span class="ml-2">means any mobile/computer/tablet device (including handsets and
                                    tablets) on which HEZKYTECH LLP allows the downloading and use of the
                                    Software/Application for providing the Services.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Hezkytech ID:</span>
                                <span class="ml-2">means a user ID provided by us to enable you to access the Software
                                    Platform.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">SAAS:</span>
                                <span class="ml-2">means Software As A Service.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Privacy Policy:</span>
                                <span class="ml-2">shall mean the Privacy Policy of HEZKYTECH LLP, in connection with
                                    the use of this Application, as may be amended from time to time.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Product(s):</span>
                                <span class="ml-2">shall mean Temperature Sensors, Humidity Sensors, Light Activity
                                    Sensors, Co2 sensors, Leak Detection Sensors, Magnetic Contact Switch Sensors,
                                    Ultrasonic Distance Sensors, dongles, Lorawan gateway routers, network interface
                                    units (NIU), USB modems, data cards, accessories, spares & supplies, consumer
                                    electronic goods, etc.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Hezkytech LLP / "we" / "us" / "our":</span>
                                <span class="ml-2">shall mean Hezkytech LLP, its Affiliates or its successors and
                                    assigns wherever the context applies.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">SD Card:</span>
                                <span class="ml-2">means and includes any secure digital memory cards used in the
                                    Device.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Seller(s):</span>
                                <span class="ml-2">shall mean the entities including manufacturers of the products which
                                    sell the products to the User directly through the Hezkytech.com website that
                                    includes Hezkytech LLP group companies / subsidiaries.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Sellers Policy / Terms and conditions:</span>
                                <span class="ml-2">shall mean the terms and conditions or policies that govern the
                                    purchase of products from the Seller, and Buyer agrees to comply with the policies
                                    or terms and conditions by accessing the products.</span>
                            </nz-list-item>
                            <nz-list-item>
                                <span class="font-semibold">Service(s):</span>
                                <span class="ml-2">means all services including but not limited to, customer support,
                                    account details, statement of accounts, usage details, SAAS login and access that
                                    HEZKYTECH LLP provides to Customers through the Application and any other services
                                    that may be included from time to time.</span>
                            </nz-list-item>
                        </nz-list>
                    </div>
                </nz-collapse-panel>


                <!-- Registration Section -->
                <nz-collapse-panel nzHeader="Registration and Activation">
                    <div class="space-y-4">
                        <p class="text-gray-700">
                            In order to access any Services, Content, you will be required to Access or login to our
                            website Hezkytech.com and also add the login credential as and when provided by the
                            Hezkytech LLP/ download and install the Application on your Device if applicable from time
                            to time which will be updated , and authenticate the access through Hezkytech ID which will
                            be activated only after authorisation from the Hezkytech LL . At the time of Login of the
                            SAAS, the SAAS will require your authorisation to acceptance of the terms and condition You
                            acknowledge that you are agreeable to provide such permissions. For activation and
                            registering for this SAAS you would be required to provide certain basic KYC documents,
                            Customer information, personally identifiable information, SAAS which should be true,
                            complete and accurate information, and agrees not to submit any particulars which you do not
                            have any right or authority to provide. Based on the information provided by you, we may
                            provide Hezkytech ID for accessing and activation of the Application/SAAS (software
                            Platform) and availing the Services. Subject to the completion of the registration process
                            and acceptance of the TOS, you are permitted to access, view your account and avail the
                            Services. The Contents of the Application are the proprietary rights of HEZKYTECH LLP, its
                            Affiliates or independent content providers who have a license agreement with HEZKYTECH LLP
                            or its Affiliates as the case may be. In order to access and use the Content, you are
                            required to create a user account with the Application, which can be accessed through
                            Hezkytech ID, or through zero level authentication using a Hezkytech SIM. You are solely
                            responsible for maintaining the confidentiality and security of your user account, Hezkytech
                            ID, access to Hezkytech SIM and for all activities that occur on or through your user
                            account. You shall take all necessary precautions to prevent unauthorised, illegal access to
                            your account through the Application and HEZKYTECH LLP is not responsible for any misuse of
                            the Hezkytech ID, password, or Hezkytech SIM, Customer mobile phone or unauthorised access
                            to your account by any third party and HEZKYTECH LLP hereby disclaims all such liability
                            arising out of any misuse of the Application downloaded by you.

                            In order to use the Application you must be 18 (Eighteen) years of age or older. To use the
                            Service(s), you will need a Device that meets the system and compatibility requirements for
                            the relevant Services, which may change from time to time, working Internet access, and
                            compatible software. Your ability to use the Service and the delivery of the Service may be
                            affected by these factors. Such system requirements shall be your responsibility.

                            HEZKYTECH LLP may access your SAAS or may request you to enter your details along with the
                            SAAS for providing Hezkytech ID, one time password, and in connection with the provision of
                            Services or for the purpose of improving the Services. You may be able to use the Services
                            only on one authorized Device connected to your SAAS. In the event you attempt to register
                            another device with the same SAAS, the use of the Application may be terminated on other
                            devices. </p>
                    </div>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Restrictions on Use of the Application" [nzShowArrow]="true">
                    <p>Your use of this Application shall be subject to:</p>
                    <ul>
                        <li>These Terms of Service</li>
                        <li>Our Privacy Policy</li>
                        <li>Any other terms, conditions, notices, or disclaimers displayed or added to the Application
                            and/or the Customer Agreement Form or any other document signed by you.</li>
                        <li>The Service(s) and Application are provided for your personal and non-commercial use only.
                        </li>
                        <li>
                            You agree not to reproduce, retransmit, distribute, disseminate, sell, broadcast, perform,
                            make available to third parties, or circulate the Application or any material, Content
                            through the Application, or to exploit any such Content for commercial purposes without the
                            express prior written consent of HEZKYTECH LLP.
                        </li>
                    </ul>
                </nz-collapse-panel>

                <!-- Price and Payment Section -->
                <nz-collapse-panel nzHeader="Price and Payment Policy">
                    <div class="space-y-4">
                        <p class="text-gray-700">The Service(s) are on chargeable basis, Hezkytech LLP offers you
                            multiple payment options online for availing the Services. However Hezkytech LLP reserves
                            its right to add or delete any mode of payment without any prior notice. The Service(s) are
                            offered and made available only to users / Customers who are lawful owners of, or, at the
                            responsibility of such owners and have the necessary authorization for using, the SAAS , and
                            for making payments through or in connection with the use of the Service(s) . If you are not
                            such a user, please discontinue the registration process and use of the services
                            immediately. The sale of Product(s) and or rendering of Services as may be available through
                            the Application will be powered by Hezkytech LLP Retail Ltd, and or its Affiliates.</p>
                        <p class="text-gray-700">The online payment mode includes credit cards , debit cards, net
                            banking, HezkytechMoney account , etc . We accept payments made by most of the major
                            credit/debit cards issued in India. Acceptance of card payment is at the sole discretion of
                            Hezkytech LLP However Hezkytech LLP reserves its right to deny acceptance of card payment
                            without any prior notice. We don't accept internationally issued credit/debit cards, which
                            are issued outside India and certain cards issued by banks in India too.</p>
                        <p class="text-gray-700">While availing any of the payment method(s) available on the
                            Application or on the Website we will not be responsible or assume any liability, whatsoever
                            in respect of any loss or damage arising directly or indirectly to you due to:</p>
                        <ul>
                            <li>Lack of authorization for any transaction/s, or</li>
                            <li>Exceeding the present limit mutually agreed by you and your issuing bank, or</li>
                            <li>Any payment issues arising out of the transaction, or</li>
                            <li>Decline of transaction for any other reason/s</li>
                        </ul>
                        <p class="text-gray-700">Hezkytech LLP will not be liable for any credit / debit card fraud. The
                            liability for use of a card fraudulently will be on you and the onus to prove otherwise
                            shall be exclusively on you. All information collected by us shall be governed as per our
                            Privacy Policy. Hezkytech LLP will not be responsible for any transactions you make for
                            using any portion of the Service(s). All claims and complaints for any failed or incorrect
                            transactions must be directed to our payment processing partners and/or your bank (as the
                            case may be).</p>
                        <p class="text-gray-700">Hezkytech LLP reserves its right to change the price of any Service(s),
                            tariff plans, available through the Application any time without any notice, to the extent
                            permitted under applicable laws. All payments made against the purchases of Service(s) on
                            Application by you shall be in Indian Rupees only. The Application or the payment gateways
                            associated with it will not facilitate transaction with respect to any other form of
                            currency with respect to the purchases made on Application.</p>
                        <p class="text-gray-700">You represent and confirm that the credit/debit card that is being used
                            is yours or that you have been specifically authorized by the owner of the credit/debit card
                            to use it. You further agree and undertake to provide the correct and valid credit card
                            details to carry out a transaction on the Application. All credit / debit card holders are
                            subject to validation checks and authorization by the card issuer. If the issuer of your
                            payment card refuses to authorize payment to us, we will not be liable for any delay or
                            non-delivery.</p>
                        <p class="text-gray-700">Hezkytech LLP reserves the right to impose limits on the number of
                            transactions which Hezkytech LLP may receive from an individual valid credit / debit / valid
                            bank account and reserves the right to refuse to process transactions exceeding such limit.
                        </p>
                        <p class="text-gray-700">You acknowledge that Hezkytech LLP will not be liable for any damages,
                            interests, claims etc. resulting from not processing a transaction or any delay in
                            processing a transaction which is beyond the control of Hezkytech LLP.</p>
                        <p class="text-gray-700">The online payment mode includes credit cards, debit card, net banking,
                            UPI, account, etc. We accept payments made by most of the major credit/debit cards issued in
                            India. Acceptance of card payment is at the sole discretion of Hezkytech LLP. However,
                            Hezkytech LLP, reserves its right to deny acceptance of card payment without any prior
                            notice. We don't accept internationally issued credit/debit cards and certain cards issued
                            by banks in India too.</p>
                        <p class="text-gray-700">While availing any of the payment method(s) available on the Website,
                            we will not be responsible or assume any liability, whatsoever in respect of any loss or
                            damage arising directly or indirectly to you due to:</p>
                        <ul>
                            <li>Lack of authorization for any transaction/s, or</li>
                            <li>Exceeding the present limit mutually agreed by you and your issuing bank, or</li>
                            <li>Any payment issues arising out of the transaction, or</li>
                            <li>Decline of transaction for any other reason/s</li>
                        </ul>
                        <p class="text-gray-700">Hezkytech LLP will not be liable for any credit / debit card fraud. The
                            liability for use of a card fraudulently will be on you and the onus to prove otherwise
                            shall be exclusively on you. All information collected by us shall be governed as per our
                            Privacy Policy. Hezkytech LLP will not be responsible for any transactions you make for
                            using any portion of the Service(s). All claims and complaints for any failed or incorrect
                            transactions must be directed to our payment processing partners and/or your bank (as the
                            case may be).</p>
                        <p class="text-gray-700">Hezkytech LLP reserves its right to change the price of any Service(s),
                            tariff plans, in the Website any time without any notice, however in compliance with all
                            applicable laws. All payments made against the purchases of Service(s) on Website by you
                            shall be in Indian Rupees only. Website will not facilitate transaction with respect to any
                            other form of currency with respect to the purchases made on Website.</p>
                        <p class="text-gray-700">You represent and confirm that the credit/debit card that is being used
                            is yours or that you have been specifically authorized by the owner of the credit / debit
                            card to use it. You further agree and undertake to provide the correct and valid credit card
                            details to carry out a transaction on the Application. All credit / debit card holders are
                            subject to validation checks and authorization by the card issuer. If the issuer of your
                            payment card refuses to authorize payment to us, we will not be liable for any delay or
                            non-delivery.</p>
                        <p class="text-gray-700">Hezkytech LLP reserves the right to impose limits on the number of
                            transactions which Hezkytech LLP may receive from an individual’s valid credit / debit /
                            valid bank account and reserves the right to refuse to process transactions exceeding such
                            limit.</p>
                        <p class="text-gray-700">You acknowledge that Hezkytech LLP will not be liable for any damages,
                            interests, claims etc. resulting from not processing a transaction or any delay in
                            processing a transaction which is beyond the control of Hezkytech LLP.</p>
                    </div>
                </nz-collapse-panel>
                <nz-collapse-panel nzHeader="No unlawful, infringing or offensive activity" [nzShowArrow]="true">
                    <p>You must not post / transmit or enable another person to post/transmit via the Application any
                        information or material or otherwise use the Application for any activity which breaches any
                        laws or regulations, infringes a third party’s rights or privacy or is contrary to any
                        applicable laws or rules. The service is accessed via the World Wide Web which is independent of
                        the Application. Your use of the World Wide Web is solely at your own risk and subject to all
                        applicable national and international laws and regulations. We have no responsibility for any
                        information or service obtained by you on the World Wide Web.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="No viruses or other interference" [nzShowArrow]="true">
                    <p>You must not transmit or enable another person to transmit via the Application any virus or other
                        information or material or otherwise use the Application in a way which:</p>
                    <ul>
                        <li>Tampers with, hinders the operation of or makes unauthorized modifications to the
                            Application;</li>
                        <li>Inhibits any other user from using the Application;</li>
                        <li>Defames, harasses, threatens, menaces or offends any person; or</li>
                        <li>Contains obscene, indecent, inflammatory or pornographic material or material that could
                            give rise to civil or criminal proceedings</li>
                    </ul>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="We may suspend or terminate your access" [nzShowArrow]="true">
                    <p>We may suspend or terminate your access to all or any part of the Application at any time, if you
                        breach these Application Terms in our reasonable opinion or in case of deactivation of SAAS or
                        at our discretion for any reasons deemed fit to us.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Content that you submit via the Application" [nzShowArrow]="true">
                    <p>This section applies if you have been allowed by us to submit, post, transmit or otherwise make
                        any material, messages, reviews, ideas, available via the Application ("your content"). You
                        agree that the Content which you may post, submit or transmit are original, or otherwise
                        entitled to you. Where you do so, you grant to us, a non-exclusive, irrevocable, perpetual,
                        worldwide, royalty-free, transferable license to use, reproduce, modify, adapt, publish or
                        communicate to the public your content for purposes of our business, and the right to
                        sub-license those rights to others. You also consent to any act that would otherwise use and
                        creative derivative works of your content. You warrant that you have the right to grant the
                        above license and that our exercise of the license rights above will not infringe the
                        intellectual property rights of any person, and you shall not post , submit, transmit or
                        otherwise make available any content which is otherwise directly or indirectly infringing any
                        third party intellectual property rights, invading others privacy, Contents which are
                        defamatory, pornographic, obscene, or otherwise contrary to public morals and violation of
                        applicable laws

                        We may monitor or review your content, but we are not obliged to do so. We may also alter or
                        remove any of your content at any time, including for operational integrity of our services.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Intellectual Property" [nzShowArrow]="true">
                    <p>All intellectual property rights in this web site, design, text, graphics, applications,
                        software, underlying source code and all other aspects belong to us and or our Affiliates.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Copyright" [nzShowArrow]="true">
                    <p>All Content included on the Application, such as text, graphics, logos, button icons, images,
                        audio clips, digital downloads, data compilations, and software, is the property of Hezkytech
                        LLP, its Affiliates or its content suppliers and is protected by the appropriate Intellectual
                        Property Laws.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Trademarks" [nzShowArrow]="true">
                    <p>The trademark 'Hezkytech LLP', its logo, trade mark "Hezkytech", its logo, and other Services
                        Marks and logos owned by and/or licensed to Hezkytech LLP and/or its Affiliates. There will be
                        certain trademarks of third parties whose products / Services are made available on the
                        Application, these marks are used for representation purposes only and Hezkytech LLP does not
                        claim any rights over these trademarks.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Intellectual Property Claims" [nzShowArrow]="true">
                    <p>Hezkytech LLP, its Affiliates or its content suppliers respect the intellectual property of
                        others. If you believe that your intellectual property rights have been used in a way that gives
                        rise to concerns of infringement, please write to us with your concerns and we will try and
                        answer your concerns.

                        No part of this Application including audio and video excerpts, may be reproduced, adapted,
                        modified or transmitted in any form or by any means, electronic, mechanical, photocopying,
                        recording or otherwise, without our prior permission.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Links" [nzShowArrow]="true">
                    <p>The Application may provide you such links for your convenience only and has no control over the
                        linked sites. The Links may be of the mobile software application owned by the Affiliate(s) or
                        owned by third parties, which may be governed by separate terms and conditions of usage. The
                        provision of Links shall be subject to the Linking Policy of HEZKYTECH LLP as may be amended
                        from time to time .We do not maintain, sponsor or recommend any third party Applications, nor
                        does it assume any responsibility for them or their content or accessibility. In particular, we
                        shall not be liable for any infringement of intellectual property rights of any person, or any
                        information or opinions on third party Applications. Your use of those linked sites, mobile
                        software applications, and services are subject to the terms of use and Privacy Policies of each
                        such site and service(s) (in addition to this Agreement and the Privacy Policy).</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Information Security" [nzShowArrow]="true">
                    <p>We are committed to safeguard the security and confidentiality of any information you provide to
                        us. We understand that you accept the inherent security implications of providing information
                        over the internet and will not hold us responsible for any breach of security or disclosure of
                        personal information. If you become aware of any problems with the security of data or the
                        Application, please contact us immediately. However Customer hereby authorizes and consents to
                        the collection, storage and use, by HEZKYTECH LLP and its Affiliates, partners, of any
                        information and data related to or derived from Customer's use of the Application, and any
                        information or data that Customers provide to HEZKYTECH LLP and its Affiliates, partners and
                        licensors ("Information"). Notwithstanding the foregoing, the Customer's personally identifiable
                        information will be used only in accordance with the Privacy Policy and to the extent permitted
                        under applicable laws. Such Information will be treated as being non confidential and
                        non-proprietary, and HEZKYTECH LLP assumes no obligation to protect confidential or proprietary
                        information (other than personally identifiable information) from disclosure and will be free to
                        reproduce, use, and distribute the Information to others without restriction. This provision
                        shall survive the termination of this TOS.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Indemnity" [nzShowArrow]="true">
                    <p>You agree to indemnify us and other persons involved in the creation of this Application for all
                        damages, losses and costs (including legal costs) which arise out of or relate to your use of
                        this Application. This indemnification includes, without limitation, liability relating to
                        copyright infringement, defamation, invasion of privacy, trade mark infringement and breaches of
                        this Application Terms.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Limitation of Liability" [nzShowArrow]="true">
                    <p>In no event Hezkytech LLP including affiliates and their respective owners, directors, officers,
                        agents, shareholders and partners shall be liable for any loss or damage whatsoever including
                        without limitation direct, indirect, punitive, incidental and consequential damages, lost
                        profits, or damages resulting from the use or inability to use the Website whether based on
                        warranty, contract, tort or in any other means or action.

                        Hezkytech LLP including affiliates and their respective owners, directors, officers, agents,
                        shareholders and partners aggregate and total liability (whether in contract, tort, negligence,
                        under an indemnity, or by statute or otherwise) shall not exceed INR 5,000/- (Rupees Five
                        Thousand) or the last payment made by the user; whichever is lesser, subject to applicable law
                        and awarded by the competent court.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Discontinuance of the use of the Application" [nzShowArrow]="true">
                    <p>Customer may elect to discontinue using the Application and the Services, by uninstalling it. If
                        the Customer's Device is stolen or lost, the Customer must immediately inform HEZKYTECH LLP
                        customer care representative to deactivate the Customer's account on the Service. Customer will
                        remain responsible for any transactions made until the Application is blocked by HEZKYTECH LLP.
                        Customer shall immediately uninstall the Application in case the Customer changes his / her
                        Device.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Inconsistent terms" [nzShowArrow]="true">
                    <p>If there is an inconsistency between these terms of use and any other terms displayed on
                        individual pages of the Application ("other terms"), the other terms will govern to the extent
                        of the inconsistency. Hezkytech LLP reserves the right to correct any ambiguities, omissions,
                        errors , in the TOS , by amending /updating the TOS and or by issuing clarifications as may be
                        necessary in due course.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="No waiver for breaches" [nzShowArrow]="true">
                    <p>If we do not act in relation to a breach of the Application Terms by you, that should not be
                        construed as a waiver of any of our rights to act in relation to that breach or any later breach
                        by you.</p>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Governing Law and Jurisdiction" [nzShowArrow]="true">
                    <p>If a dispute arises regarding these Terms, the laws of the Republic of India will apply and
                        shall be subject to the exclusive jurisdiction of courts in Mumbai, India.</p>
                </nz-collapse-panel>

                <!-- Grievance Section -->
                <nz-collapse-panel nzHeader="Redressal of Grievance">
                    <div class="space-y-4">
                        <p class="text-gray-700">
                            If you have any complaints or concerns with regards to content or to report any abuse of
                            applicable laws, breach of Terms of this Application, or any content of this Application
                            is in violation of your rights or the services through the Application is in violation
                            of Rule 3 of the Information Technology (Intermediary Guidelines) Rules, 2021 (Rules.),
                            then you may immediately contact the Grievance Officer in the following manner
                        </p>
                        <ul>
                            <li>
                                Sending a request in writing or through email signed with electronic signature
                                identifying the content alleged to be in infringement of your rights as the rightful
                                owner or affecting you prejudicially;
                            </li>
                            <li>
                                Providing your contact information including email, address, and telephone number
                                where
                                you can be contacted if required.
                            </li>
                            <li>
                                Giving a declaration cum undertaking along with necessary documents establishing you
                                (i)
                                as the rightful owner of the content to be disabled/ affecting you prejudicially,
                                (ii)
                                as an affected person, stating that the information submitted is true, complete &
                                accurate and no material fact has been hidden, and also stating that Hezkytech LLP,
                                its
                                Affiliates, Directors, employees, including Grievance Officer shall not be liable
                                for
                                any loss or damage or claim for relaying on such requests.
                            </li>
                        </ul>
                        <p class="text-gray-700">Contact Grievance Officer:</p>
                        <div class="bg-gray-50 p-4 rounded-lg">
                            <p class="font-semibold">Name: Radha Nair</p>
                            <p>Email: grievance.officer&#64;hezkytech.com</p>
                        </div>
                    </div>
                </nz-collapse-panel>

                <nz-collapse-panel nzHeader="Disclaimer" [nzShowArrow]="true">
                    <p>Hezkytech LLP is only facilitating / providing platform vide giving access to Hezkytech.com
                        to the Users to communicate or transact with one another and does not sell the products. The
                        products are sold by the concerned Seller using Hezkytech.com website (that may include
                        subsidiaries / group companies of Hezkytech LLP) and therefore the User are advised to check
                        the terms and conditions of the Seller prior to buying the products and shall have recourse
                        against the seller for any breach of conditions, representation or warranties by the Sellers
                        or manufacturers of the products as provided in the terms and conditions of the purchase
                        accepted by the Buyer and Hezkytech LLP Hezkytech expressly disclaims any and all
                        responsibility and liability in that regard. In no event Hezkytech LLP Hezkytech will shall
                        be liable for any information, content, materials, products or services available through
                        this website.

                        To the maximum extent permitted by law, we disclaim all liability for any errors or
                        omissions contained in this information or any failure to update or correct this information
                        and use and the Services by this Application which is on "as-is" and "with all faults and
                        risks basis" and without warranties of any kind, to the maximum extent permitted under laws,
                        we disclaim all warranties whether express or implied including merchantability, fitness for
                        a particular purpose, non-infringement or that use of the services will be uninterrupted or
                        error-free, or otherwise related to interoperability, compatibility of the Application with
                        your mobile devices . You understand that accessing the internet or telecommunication
                        services may be harmful or cause losses, injury or damages to you and other people that may
                        access pilot service through you, or your equipment and other tangible and intangible
                        properties. As a user of this Application you should make your own enquiries before entering
                        into any transaction on the basis of or in hezkytech LLP upon the content. To the extent
                        permitted by law, we and our employees, officers, agents and contractors exclude all
                        liability for any loss or damage (including without limitation, indirect, special or
                        consequential loss or damage) arising from the use of, or hezkytech LLP upon the content
                        whether or not this is caused by a negligent act. We expressly reserve the right, in its
                        discretion, to suspend, change, modify, add or remove portions of the Application and to
                        restrict or terminate the use and accessibility of the Application without any prior notice.
                        If any provision of this Disclaimer is invalid or unenforceable, such will not render all
                        terms unenforceable or invalid but rather the terms will be read and construed as if the
                        invalid or unenforceable provision(s) were not contained therein. Any other Product(s),
                        Service(s) and company names mentioned herein may be trademarks, service marks or trade
                        names of their respective owners. Your access to this Application should not be construed as
                        granting, by implication, estoppel or otherwise, any license or right to use any marks
                        appearing on the Application without the prior</p>
                </nz-collapse-panel>
            </nz-collapse>
        </div>
    </nz-card>
</div>