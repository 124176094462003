import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { clearAppState, saveLoginOrigin } from './app.actions';

export interface appState {
    loginOrigin: string | null;
}

export const initialState: appState = {
    loginOrigin: null
};

export const appFeature = createFeature({
    name: 'app',
    reducer: createReducer(
        initialState,
        on(saveLoginOrigin, (state, { origin }) => ({ ...state, loginOrigin: origin })),
        on(clearAppState, () => ({ ...initialState })),
    ),
});

export const { name, reducer, selectAppState } = appFeature;
export const selectLoginOrigin = createSelector(selectAppState, (state) => state.loginOrigin);
